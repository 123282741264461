<template>
  <div class="home">
    <el-container class="pageBg">
      <div class="pageTitImgBox">
        <div class="pti_content">
          <div class="pageTitLogo">
            <img src="../../assets/imgs/zhgd_logo.png" />
          </div>
          <div class="pageTitLogo pageTitLogo2">
            <img src="../../assets/imgs/hk_logo.png" />
          </div>
        </div>
        <div class="pageTitLogo pageTitLogo3">
          <img src="../../assets/imgs/hk_ub_tit_lef.png" />
        </div>
      </div>
      <div class="pageTitBox">
        <img src="../../assets/imgs/hk_ub_tit.png" />
      </div>
      <div class="formBox">
        <div class="fb_titBox">
          <div class="form_tit">领取福利</div>
          <p class="userTotal">
            已领取 <span class="ut_span"> {{ userTotal }}</span
            >份
          </p>
        </div>
        <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">立即领取</el-button>
          </el-form-item>
        </el-form>
      </div>
      <p class="bottom_dec">注：领取后可通过手机号登录菏康APP(默认密码123456)</p>
    </el-container>
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
import { userExperience, userTotal } from '@/api/index.js'
import session from '@/utils/session'
import { Alert } from 'element-ui'
export default {
  data() {
    return {
      title: '菏康安防新春送福活动',
      userTotal: 0,
      token:
        'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI5ODc2NTQzNDU2Nzg3NjUiLCJzdWIiOiLluILlnLrlsI9BIiwiaWF0IjoxNzA0MzgzODY1LCJyb2xlTmFtZSI6IjEiLCJwb3dlciI6IjEifQ.P1yiUUNBACyLCtdPBM2vpLHvdTNmNOQPLoKp95R82eg',
      propertyPhone: null,
      ruleForm: {
        roomId: null,
        name: '',
        mobile: ''
      }
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = this.title
    // 获取URL的查询参数
    const roomId = this.$route.query.roomId
    this.ruleForm.roomId = roomId
    const propertyPhone = this.$route.query.propertyPhone
    this.propertyPhone = propertyPhone
    this.getUserTotal()
  },
  methods: {
    async getUserTotal() {
      let res = await userTotal()
      if (res.success) {
        this.userTotal = res.data
      }
    },
    async submitForm() {
      if (!/^1[345789]\d{9}$/.test(this.ruleForm.mobile)) {
        alert('请输入正确的手机号!')
      } else {
        const data = {
          roomId: this.ruleForm.roomId,
          propertyPhone: this.propertyPhone,
          name: this.ruleForm.name,
          mobile: this.ruleForm.mobile
        }
        const res = await userExperience(this.ruleForm)
        if (res.success) {
          session.setData('propertyInfo', data)
          session.setSession(this.token)
          this.$router.push({ name: 'UserDomeHome' })
        } else {
          this.$message.error(res.message)
        }
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
p {
  padding: 0.25rem;
  margin: 0;
  line-height: 1.6;
}
.pageBg {
  background-image: url('../../assets/imgs/hk_ub.png');
  background-size: 100% auto;
  height: 100vh;
  display: block;
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 10px;
  font-size: 62.5%;
}
.pageTitBox {
  margin: 0 auto;
  margin: 1.5rem 0;
}
.pageTitLogo {
  width: 5.37rem;
  height: 2rem;
}
.pageTitLogo3 {
  width: 8rem;
  height: 2rem;
}
.pageTit {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.linear-gradient {
  background-image: linear-gradient(#ffffff, #d9fff1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pageDec {
  font-size: 0.75rem;
  padding-top: 0.25rem;
}
.downBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.87rem 0;
}
.iphoneBox,
.androidBox {
  width: 48%;
  height: 2.43rem;
  background: #ffffff;
  border-radius: 1.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.35rem 0.35rem -0.12rem rgba(6, 115, 115, 0.5);
}
.iphone_logo,
.android_logo {
  width: 1rem;
  height: 1rem;
}
.downBtnTxt {
  font-size: 0.93rem;
  font-weight: bold;
  padding-left: 0.4rem;
}
.textBox {
  font-size: 1.56rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem;
}
.textTit {
  font-size: 0.81rem;
  font-weight: bold;
  margin-top: 1rem;
}
.textDec {
  font-size: 0.81rem;
  text-align: justify;
}
.formBox {
  background: #f8e3b9;
  border-radius: 16px;
  padding: 0 1.5rem;
}
.fb_titBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form_tit {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 1.2rem 0;
}
.demo-ruleForm {
  padding-bottom: 10px;
}
/deep/ .el-form-item {
  margin-bottom: 1.3rem;
}
/deep/ .el-form-item__label {
  font-size: 1rem;
  color: #202626;
  line-height: 1.5rem;
  padding-bottom: 0.5rem !important;
}
/deep/ .el-input__inner {
  font-size: 1rem;
  background: #f2f3f4;
  border: none;
  height: 3rem;
}
/deep/ .el-button {
  font-size: 1rem;
  width: 100%;
  background: linear-gradient(to right, #e72822, #941e23);
  border: linear-gradient(to right, #e72822, #941e23);
  height: 3rem;
}
/deep/ .el-button--primary:focus,
.el-button--primary,
.el-button--primary:hover {
  background: linear-gradient(to right, #e72822, #941e23);
  border: none;
}
.userTotal {
  color: #202626;
  font-size: 0.8rem;
}
.ut_span {
  font-weight: bold;
  padding-right: 0.2rem;
}
.pageTitImgBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}
.pti_content {
  display: flex;
  align-items: center;
  justify-content: left;
}
.pageTitLogo2 {
  margin-left: 0.5rem;
}
.bottom_dec {
  padding: 0.5rem 0;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
}
</style>
