<template>
  <div class="pageBox">
    <div class="pb_titleBox">
      <div class="logon">
        <img src="../../assets/imgs/logo_white.svg" alt="" />
      </div>
    </div>
    <div class="pb_content">
      <h1 class="pbc_title">菏康智慧养老统一身份认证平台</h1>
      <el-row class="pbc_content">
        <el-col :span="6" v-for="(item, index) in itemData" :key="index" class="pbcc_item">
          <div class="grid-content" @click="openLink(item)">
            <div class="gc_icon">
              <img :src="item.icon" alt="" class="img" />
            </div>
            <div class="gc_txt">{{ item.title }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    downAppVue
  },
  data() {
    return {
      title: '统一身份认证平台',
      itemData: [
        {
          icon: require('@/assets/imgs/community.svg'),
          title: '社区养老服务管理系统',
          link: 'https://community.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/jujia.svg'),
          title: '居家养老服务管理系统',
          link: 'https://property.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/jigou.svg'),
          title: '机构养老服务管理系统',
          link: 'https://community.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/jiazheng.svg'),
          title: '家政照料服务管理系统',
          link: 'https://service.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/zhengfu.svg'),
          title: '民政监管服务管理系统',
          link: 'https://gov.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/zinv.svg'),
          title: '子女监管服务管理系统',
          link: ''
        },
        {
          icon: require('@/assets/imgs/shouhou.svg'),
          title: '设备售后服务管理系统',
          link: 'https://operate.zghkyy.com/'
        },
        {
          icon: require('@/assets/imgs/pingtai.svg'),
          title: '平台运营服务管理系统',
          link: 'https://operate.zghkyy.com/'
        }
      ],
      downApp: false
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
  },
  methods: {
    openLink(data) {
      const link = data.link
      if (!link) {
        this.downApp = true
      } else {
        const w = window.open('about:blank')
        w.location.href = link
      }
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style scoped>
.img {
  width: 100%;
  height: 100%;
}
.pageBox {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/imgs/logonBg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.pb_titleBox {
  width: 100%;
  padding: 24px 40px;
}
.logon {
  height: 44px;
}
.pb_content {
  padding-top: 4%;
}
.pbc_title {
  font-size: 40px;
  text-align: center;
}
.pbc_content {
  width: 1200px;
  margin: 0 auto;
  padding-top: 3%;
}
.pbcc_item {
  padding: 5px !important;
}
.grid-content {
  background: rgba(9, 190, 190, 0.1);
  border: 1px solid #09bebe;
  border-radius: 8px;
  padding: 40px 0;
  cursor: pointer;
}
.grid-content:hover {
  background: rgba(9, 190, 190, 0.5);
}
.gc_icon {
  width: 56px;
  height: 56px;
  margin: 0 auto;
}
.gc_txt {
  text-align: center;
  padding-top: 24px;
}
</style>
