<template>
  <div class="home">
    <el-container class="pageBg">
      <div class="pageTitImgBox">
        <div class="pti_content">
          <div class="pageTitLogo pageTitLogo2">
            <img src="../../assets/imgs/hk_logo.png" />
          </div>
        </div>
        <div class="pageTitLogo pageTitLogo3">
          <img src="../../assets/imgs/hk_ub_tit_lef.png" />
        </div>
      </div>
      <div class="pageTitBox">
        <img src="../../assets/imgs/hk_ub_tit2.png" />
      </div>
      <div class="formBox">
        <div class="fb_titBox">
          <div class="form_tit">快速报装</div>
        </div>
        <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
          <el-form-item prop="name">
            <el-input placeholder="请输入姓名" v-model="ruleForm.name"> </el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input placeholder="请输入验证码" v-model="ruleForm.code">
              <template slot="append">
                <div @click="openCode()">{{ codeText }}</div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-select v-model="ruleForm.package" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label + item.money" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input placeholder="请输入详细地址" v-model="ruleForm.detailAdd"> </el-input>
          </el-form-item>
          <!-- <el-form-item prop="name" class="checkedBox">
            <el-checkbox v-model="checked" class="checkedContent"></el-checkbox>
            <span
              >我已阅读理解并同意菏康 <span @click="openDialog(0)">《服务协议》</span>
              <span @click="openDialog(1)">《隐私政策》</span></span
            >
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">立即申请</el-button>
          </el-form-item>
        </el-form>
      </div>
      <p class="bottom_dec">注：申请后可通过手机号登录菏康APP查看进度(默认密码hk123456)</p>
    </el-container>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <span>{{ dialogContent }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userValidateCode, userQuick } from '@/api/index.js'
export default {
  data() {
    return {
      title: '菏康安防快速报装',
      sysCode: null,
      ruleForm: {
        name: null,
        mobile: null,
        detailAdd: null,
        package: null,
        code: null,
        make: null
      },
      codeText: '获取验证码',
      options: [
        {
          value: '1',
          label: '基础套餐',
          money: '--¥365元/年「限量发售」'
        },
        {
          value: '2',
          label: '尊享套餐',
          money: '--¥680元/年「限量发售」'
        }
      ],
      centerDialogVisible: false,
      dialogContent: '需要注意的是内容是默认不居中的'
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = this.title
  },
  methods: {
    async openCode() {
      if (!/^1[345789]\d{9}$/.test(this.ruleForm.mobile)) {
        alert('请输入正确的手机号!')
        this.ruleForm.mobile = null
      } else {
        this.codeText = '已发送'
        let res = await userValidateCode(this.ruleForm.mobile)
        if (res.success) {
          this.sysCode = res.data
        } else {
          alert('验证码发送失败!')
        }
      }
    },
    openDialog(data) {
      if (data == 0) {
        this.dialogContent = '我是服务协议'
      } else if (data == 1) {
        this.dialogContent = '我是隐私政策'
      }
      this.centerDialogVisible = true
    },
    async submitForm() {
      const ruleForm = this.ruleForm
      if (
        ruleForm.name != null &&
        ruleForm.name != '' &&
        ruleForm.mobile != null &&
        ruleForm.mobile != '' &&
        ruleForm.detailAdd != null &&
        ruleForm.detailAdd != '' &&
        ruleForm.code != null &&
        ruleForm.code != '' &&
        ruleForm.package != null &&
        ruleForm.package != ''
      ) {
        if (this.sysCode == this.ruleForm.code) {
          var jsonObject = {}
          jsonObject.package = this.ruleForm.package
          jsonObject.detailAdd = this.ruleForm.detailAdd
          this.ruleForm.make = JSON.stringify(jsonObject)
          let res = await userQuick(this.ruleForm)
          if (res.success) {
            alert('申请成功，工作人员会在1-2天内联系您，请保持电话畅通!')
          } else {
            alert(res.message)
          }
        } else {
          alert('验证码输入错误!')
        }
      } else {
        alert('请完整填写信息!')
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
p {
  padding: 0.25rem;
  margin: 0;
  line-height: 1.6;
}
.pageBg {
  background-image: url('../../assets/imgs/hk_ub.png');
  background-size: 100% 100%;
  height: 100%;
  display: block;
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 10px;
  font-size: 62.5%;
}
.pageTitBox {
  margin: 0 auto;
  margin: 1.5rem 0;
}
.pageTitLogo {
  width: 5.37rem;
  height: 2rem;
}
.pageTitLogo3 {
  width: 8rem;
  height: 2rem;
}
.pageTit {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.linear-gradient {
  background-image: linear-gradient(#ffffff, #d9fff1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pageDec {
  font-size: 0.75rem;
  padding-top: 0.25rem;
}
.downBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.87rem 0;
}
.iphoneBox,
.androidBox {
  width: 48%;
  height: 2.43rem;
  background: #ffffff;
  border-radius: 1.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.35rem 0.35rem -0.12rem rgba(6, 115, 115, 0.5);
}
.iphone_logo,
.android_logo {
  width: 1rem;
  height: 1rem;
}
.downBtnTxt {
  font-size: 0.93rem;
  font-weight: bold;
  padding-left: 0.4rem;
}
.textBox {
  font-size: 1.56rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem;
}
.textTit {
  font-size: 0.81rem;
  font-weight: bold;
  margin-top: 1rem;
}
.textDec {
  font-size: 0.81rem;
  text-align: justify;
}
.formBox {
  background: #f8e3b9;
  border-radius: 16px;
  padding: 0 1.5rem;
}
.fb_titBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form_tit {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 1.2rem 0;
}
.demo-ruleForm {
  padding-bottom: 10px;
}
/deep/ .el-form-item {
  margin-bottom: 1.3rem;
}
/deep/ .el-form-item__label {
  font-size: 1rem;
  color: #202626;
  line-height: 1.5rem;
  padding-bottom: 0.5rem !important;
}
/deep/ .el-input__inner {
  font-size: 1rem;
  background: #f2f3f4;
  border: none;
  height: 3rem;
}
/deep/ .el-button {
  font-size: 1rem;
  width: 100%;
  background: linear-gradient(to right, #e72822, #941e23);
  border: linear-gradient(to right, #e72822, #941e23);
  height: 3rem;
}
/deep/ .el-button--primary:focus,
.el-button--primary,
.el-button--primary:hover {
  background: linear-gradient(to right, #e72822, #941e23);
  border: none;
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  border: none;
  color: #941e23;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-dialog--center {
  width: 90% !important;
}
/deep/ .el-dialog--center .el-button {
  font-size: 1rem;
  width: 40%;
  background: linear-gradient(to right, #e72822, #941e23);
  border: linear-gradient(to right, #e72822, #941e23);
  height: 3rem;
}
.userTotal {
  color: #202626;
  font-size: 0.8rem;
}
.ut_span {
  font-weight: bold;
  padding-right: 0.2rem;
}
.pageTitImgBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}
.pti_content {
  display: flex;
  align-items: center;
  justify-content: left;
}
.pageTitLogo2 {
  margin-left: 0.5rem;
}
.bottom_dec {
  padding: 0.5rem 0;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
}
.checkedBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkedContent {
  padding-right: 10px;
}
/deep/ .el-form-item__content {
  line-height: 20px;
}
.checkedBox span span {
  color: #941e23;
}
</style>
