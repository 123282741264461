<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              菏康安防系统是集物联网、云计算、移动互联网等新一代信息通讯技术背景下而搭建的一套综合应急安全监控中心，优势在于它是以物业或社区作为应急第一监控中心，以智能终端和专属热线为纽带，将每个家庭串联起来，强化居家安防及应急救助服务，重点关注独居、空巢老人的主动关怀和特殊时段的应急服务。同时，菏康作为应急第二监控监督中心，确保突发事件反馈的准确性、及时性，做到立即报警、精准定位等功能，以保证居⺠的人身财产安全。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openProperty">登录物业中心</div>
              <!-- <div class="bb_move2" @click="openPropertyRegister">物业入驻申请</div> -->
              <div class="bb_move2" @click="openCommunity">登录社区中心</div>
              <div class="bb_move3" @click="openUser">用户中心</div>
              <div class="bb_playBox" @click="openCloseVideo">
                <div class="bbp_img">
                  <img src="../../assets/imgs/banner_playBtn.png" />
                </div>
                <span>观看影片</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品设计理念 -->
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">产品设计理念</div>
          <p class="ci_p">
            以“智慧养老”为核心，利用先进的互联网技术，建设面向居家老人、社区的物联网的“智慧城市”生态系统，提供实时、快捷、高效、物联化、智能化、科技化的生活服务。借助“养老”和“健康”综合服务平台，将智慧医疗、智慧安防、智慧旅游、智慧出行、便民电商、运营商、服务商、个人、家庭连接起来，满足老年人多样化、多层次的需求。
          </p>
        </div>
      </div>
      <div class="service_data">
        <div v-for="(item, i) in technologyInfo" :key="i">
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
      <!-- 提供服务 -->
      <div class="content_titBox service_bg">
        <div class="sb_inner">
          <div class="ci_title">24h多方守护</div>
          <p class="ci_p">
            有突发事件发生时，三方会同时收到报警信息，系统还配置了超时提醒机制，最大程度上保证了不会遗漏警情。
          </p>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" class="cd_itemBox0">
              <div class="itemsText">
                <div style="font-size: 32px">物业监控中心</div>
                <p>
                  作为菏康用户您在任何时间，任何情况下通过菏康APP或菏康为您配置的无线座机联系物业寻求帮助；同时，我们将通过NB-LOT物联网设备24h守护您的安全，最大程度消除安全隐患。
                </p>
              </div>
            </el-col>
            <el-col :span="8" class="cd_itemBox1">
              <div class="itemsText">
                <div style="font-size: 32px">用户自助中心</div>
                <p>
                  用户自助中心用于实时查看设备运行状况、接收设备报警通知、自主解除报警；自主关联家庭成员，设备触发报警时及时向成员发送报警、处理短信通知、在线设备报修等。
                </p>
              </div>
            </el-col>
            <el-col :span="8" class="cd_itemBox2">
              <div class="itemsText">
                <div style="font-size: 32px">菏康监控中心</div>
                <p>
                  菏康监控中心是对用户呼救、设备报警事件、健康档案、家庭财险、物业所承载的服务质量等综合管理监督监控中心，是对用户安全的双重保障。时刻监控设备运行状况，为用户提供设备检修、维护、更换服务。
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 我们采用的设备 -->
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">我们采用的设备</div>
          <p class="ci_p">
            我们所采用的设备均严格按照国家标准、安全第一、质量保障，依据大多数家庭使用场景定制而成；更多智能设备正在研发中
          </p>
        </div>
      </div>
      <!-- 设备明细 -->
      <div class="service_data">
        <div v-for="(item, i) in dataInfo" :key="i">
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
      <!-- 专项服务 -->
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">我们能带来哪些服务</div>
          <p class="ci_p">
            以“智慧养老”为核心，利用先进的互联网技术，建设面向居家老人、社区的物联网的“智慧城市”生态系统，提供实时、快捷、高效、物联化、智能化、科技化的生活服务。借助“养老”和“健康”综合服务平台，将智慧医疗、智慧安防、智慧旅游、智慧出行、便民电商、运营商、服务商、个人、家庭连接起来，满足老年人多样化、多层次的需求。
          </p>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in privilegeInfo" :key="index">
              <div class="pi_items">
                <div class="pii_tit">{{ item.title }}</div>
                <el-row :gutter="20" style="text-align: start">
                  <el-col :span="12" v-for="(v, i) in item.data" :key="i" style="padding-bottom: 12px">
                    <p style="font-weight: bold">{{ v.title }}</p>
                    <p
                      style="
                        opacity: 0.7;
                        display: inline-block;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ v.data }}
                    </p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 家庭财产 -->
      <div class="privilegeBox" style="background: #09be64; margin: 0">
        <div class="ct_innerBox" style="color: #fff">
          <div class="ci_title">
            多一份保障 多一份安全
            <span class="cit_tag">
              <img src="../../assets/imgs/security_img_8.png" />
            </span>
          </div>
          <p class="ci_p">
            开通菏康安防服务即可获赠一份家庭财产险，保障更是多达14种，几乎涵盖了居家多场景全方位保障，保额最高达500万，
            遇到安全事故时可最大程度降低财产损失。
          </p>
        </div>
        <div class="content_dataBox familyDataBox">
          <div v-for="(item, index) in familyInfo" :key="index" class="fd_items">
            <div class="fdi_inner">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <!-- 专属顾问 -->
      <div class="privilegeBox" style="background: #fff; margin: 0">
        <div class="ct_innerBox">
          <div class="ci_title">专属顾问</div>
          <p class="ci_p">7x24h服务 了解售前售后顾问联系方式，在需要的时候为您提供更全面的服务。</p>
          <p style="padding-top: 24px">全国统一热线 | 区域专属顾问 | 小区物业中心</p>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <!-- 播放视频 -->
    <div class="hkPlayVideo" v-if="videoStatus == true">
      <div class="hkp_btn" @click="openCloseVideo">关闭</div>
      <playVideoVue :src="src" :second="3" />
    </div>
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import playVideoVue from '../../component/playVideo.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    playVideoVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      videoStatus: false,
      title: '菏康安防物联网综合应急监控中心',
      technologyInfo: [
        {
          lable: 0,
          imgUrl: require('@/assets/imgs/security_img_1.png'),
          title: '物联网技术',
          data: '物联网技术是新一代信息技术的重要组成部分，是互联网基础上的延伸和扩展的网络，将各种信息传感设备与网络结合起来而形成的一个巨大网络，实现任何时间、任何地点，人、机、物的互联互通。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/security_img_2.png'),
          title: 'NB-IOT 智能物联网设备',
          data: 'NB-IoT是IoT领域一个新兴的技术，支持低功耗设备在广域网的蜂窝数据连接，也被叫作低功耗广域网(LPWAN)。NB-IoT支持待机时间长、对网络连接要求较高设备的高效连接。据说NB-IoT设备电池寿命可以提高至少10年，同时还能提供非常全面的室内蜂窝数据连接覆盖。'
        }
      ],
      dataInfo: [
        {
          lable: 0,
          imgUrl: require('@/assets/imgs/security_img_6.png'),
          title: '智能无线插卡电话机',
          data: '专老年人设计的一款智能无线插卡电话机，紧急情况下，老人可按SOS键拨出求救电话，物业在接到电话第一时间处理，给家里老人带来更好的安全守护；多组亲情号码，在免提或拿起听筒时一件拨出。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/security_img_7.png'),
          title: '安防报警设备',
          data: '菏康所提供的设备均为新一代物联网设备，它实现了人与物、物与物的泛在连接，它能实时采集使用环境及使用状态，一旦设备检测发生异常报警会将消息同步至客户端；同时，设备之间也建立了连接，比如烟雾探测器触发报警异常，所关联的燃气设备阀门将会自动关闭「注意：安全考虑，关闭后需手动打开燃气阀门」。'
        }
      ],
      privilegeInfo: [
        {
          title: '安防特权',
          data: [
            {
              title: '紧急呼救',
              data: '突发状况一键呼救'
            },
            {
              title: '远程关闭燃气',
              data: '手机远程关闭燃气阀门'
            },
            {
              title: '烟感报警',
              data: '24h不间断监测'
            },
            {
              title: '燃气报警',
              data: '24h不间断监测'
            }
          ]
        },
        {
          title: '通知特权',
          data: [
            {
              title: '报警短信通知',
              data: '及时发送报警信息'
            },
            {
              title: '站内报警通知',
              data: 'APP接收报警信息'
            },
            {
              title: '结果短信通知',
              data: '及时发送结果信息'
            },
            {
              title: '站内结果通知',
              data: '24h不间断监测'
            }
          ]
        },
        {
          title: '处理特权',
          data: [
            {
              title: '自主处理',
              data: 'APP自主处理'
            },
            {
              title: '物业处理',
              data: '监控、处理'
            },
            {
              title: '平台处理',
              data: '监控、处理'
            },
            {
              title: '全方位守护',
              data: '精准定位、处理、事件溯源'
            }
          ]
        },
        {
          title: '设备特权',
          data: [
            {
              title: '智能无线电话',
              data: '老人专用电话'
            },
            {
              title: 'NB烟雾探测器',
              data: '物联网探测器'
            },
            {
              title: 'NB燃气探测器',
              data: '物联网探测器'
            },
            {
              title: '免费使用',
              data: '免费提供所需设备'
            }
          ]
        },
        {
          title: '维护特权',
          data: [
            {
              title: '定期监测',
              data: '设备与运行环境检测'
            },
            {
              title: '一键报修',
              data: '用户发现问题可自主报修'
            },
            {
              title: '免费维修',
              data: '及时上门维修'
            },
            {
              title: '免费换新',
              data: '设备无法使用免费换新'
            }
          ]
        },
        {
          title: '档案管理',
          data: [
            {
              title: '成员档案',
              data: '关联家庭成员'
            },
            {
              title: '健康档案',
              data: '设置健康档案特殊关怀'
            },
            {
              title: '紧急联系人',
              data: '突发状况一键拨打'
            },
            {
              title: '呼警档案',
              data: '事件处理、溯源、分析'
            }
          ]
        }
      ],
      familyInfo: [
        {
          title: '房屋主体'
        },
        {
          title: '室内装潢及室内附属设施'
        },
        {
          title: '室内财产损失'
        },
        {
          title: '火灾、爆炸责任'
        },
        {
          title: '高空坠物责任'
        },
        {
          title: '燃气财产损失'
        },
        {
          title: '燃气第三责任'
        },
        {
          title: '家用电器用电安全'
        },
        {
          title: '水暖管爆裂损失'
        },
        {
          title: '房屋渗水损失'
        },
        {
          title: '房东租金/住户额外租房'
        },
        {
          title: '家庭雇佣第三者责任'
        },
        {
          title: '家庭雇佣人员意外责任'
        },
        {
          title: '家庭补偿金'
        },
        {
          title: '......'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    // 关闭视频
    openCloseVideo() {
      this.videoStatus = !this.videoStatus
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/'
    },
    openPropertyRegister() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/#/register'
    },
    openCommunity() {
      const w = window.open('about:blank')
      w.location.href = 'https://community.zghkyy.com/'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/security_img_0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #09bebe;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}
.bb_move2 {
  background: #dddd24;
  color: #202626;
  padding: 8px 24px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}
.bb_move3 {
  background: #24dd80;
  color: #fff;
  padding: 8px 24px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}
.bb_move3:hover {
  background: #5ee5a2;
  cursor: pointer;
}
.bb_move2:hover {
  background: #e5e55e;
  cursor: pointer;
}
.bb_playBox {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
}
.bbp_img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.ci_txtBox {
  max-width: 630px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.content_titBox {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.cd_itemBox0 {
  background-image: url('../../assets/imgs/security_img_3.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}
.cd_itemBox1 {
  background-image: url('../../assets/imgs/security_img_4.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}
.cd_itemBox2 {
  background-image: url('../../assets/imgs/security_img_5.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}
.itemsText {
  text-align: justify;
  padding: 100px 10px 10px 10px;
}
.service_bg {
  background: #056767;
  color: #fff;
  padding: 80px 40px;
}
.sb_inner {
  max-width: 1100px;
  margin: 0 auto;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: 447px;
  border-radius: 24px;
  overflow: hidden;
}
.bb_move:hover {
  background: #24dddd;
  cursor: pointer;
}
.bb_playBox:hover {
  cursor: pointer;
  opacity: 1;
}
.hkPlayVideo {
  position: fixed;
  top: 15vh;
  left: 25vw;
  z-index: 9999;
}
.hkp_btn {
  position: absolute;
  width: 60px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  background: #cb2f2f;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  z-index: 99999;
  right: 12px;
  top: 12px;
}
.hkp_btn:hover {
  background: #e55e5e;
  cursor: pointer;
}
.privilegeBox {
  margin-top: 150px;
  background: #ebf5f5;
  text-align: center;
  padding: 80px 40px;
}
.pi_items {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 24px;
}
.pii_tit {
  font-size: 30px;
  color: #09bebe;
  padding-bottom: 24px;
}
.familyDataBox {
  display: flex;
  flex-wrap: wrap;
}
.fd_items {
  width: 262px;
  padding: 10px;
}
.fdi_inner {
  padding: 12px 0;
  background: #fff;
  border-radius: 8px;
}
.cit_tag {
  position: absolute;
  margin-left: 12px;
  padding-top: 8px;
  width: 100px;
  height: auto;
}
</style>
