import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import store from './store'
import './assets/css/public.css'
import { formatDate, parseToEvent } from '@/utils/index.js'

// import axios from 'axios'
// Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api'

Vue.config.productionTip = false
Vue.prototype.formatDate = formatDate
Vue.prototype.parseToEvent = parseToEvent

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
