<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              为满足中老年人购物需求和商品选择难题，菏康精心挑选适用于中老年人日常所需，包含生活用品、养生器材、医疗器械、文化娱乐、时尚服饰、电子产品、保健产品等众多商品种类。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openUser">查看好物</div>
            </div>
          </div>
        </div>
      </div>
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">我们的选品要求</div>
          <p class="ci_p">
            随着年龄的增长，人们越来越关注健康问题。尤其是中老年人，在这个时代他们不仅要保证自己的身体健康，还要注意自己的心理状态。为了让大家更轻松地购物，我们一直严格如下选品要求
          </p>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in privilegeInfo" :key="index">
              <div class="pi_items" style="display: flex; align-items: center">
                <div style="width: 80px; height: 80px">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-left: 16px">
                  <div class="pii_tit">{{ item.title }}</div>
                  <p style="opacity: 0.7">{{ item.data }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 专属顾问 -->
      <div class="privilegeBox" style="background: #fff; margin: 0">
        <div class="ct_innerBox">
          <div class="ci_title">专属顾问</div>
          <p class="ci_p">7x24h服务 了解售前售后顾问联系方式，在需要的时候为您提供更全面的服务。</p>
          <p style="padding-top: 24px">全国统一热线 | 区域专属顾问 | 小区物业中心</p>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      title: '菏康好物只选中老年人用得到的物品',
      privilegeInfo: [
        {
          imgUrl: require('@/assets/imgs/shop_img1.png'),
          title: '中老年受众人群',
          data: '一切围绕中老年人群实际需求选品'
        },
        {
          imgUrl: require('@/assets/imgs/shop_img2.png'),
          title: '高频率',
          data: '刚需型生活所需'
        },
        {
          imgUrl: require('@/assets/imgs/shop_img3.png'),
          title: '品牌商家',
          data: '行业标杆、商家信用、商品质量'
        },
        {
          imgUrl: require('@/assets/imgs/shop_img4.png'),
          title: '用户满意度',
          data: '销售量、复购率、满意度'
        },
        {
          imgUrl: require('@/assets/imgs/shop_img5.png'),
          title: '高性价比',
          data: '质量、价格、实用率'
        },
        {
          imgUrl: require('@/assets/imgs/shop_img6.png'),
          title: '售前/售后服务',
          data: '具有完整的售前售后服务流程'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/shop_img0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #fff;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #202626;
  margin-right: 16px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.bb_move:hover {
  background: #24dddd;
  color: #fff;
  cursor: pointer;
}
.privilegeBox {
  margin-top: 150px;
  text-align: center;
  padding: 0 40px;
  padding-bottom: 150px;
}
.pi_items {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 24px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.025);
}
.pii_tit {
  font-size: 24px;
  text-align: left;
}
</style>
