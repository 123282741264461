<template>
  <el-dialog @close="close" title="请前往菏康养老APP查看" :visible.sync="downApp" width="20%" center>
    <div class="contentBox">
      <div style="text-align: center; padding-bottom: 24px">暂不支持用户PC端查看，请扫描下方二维码下载APP</div>
      <div style="width: 160px; height: 160px; margin: 0 auto">
        <img src="../assets/imgs/downApp.png" alt="" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="downApp = false">我知道了</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['downApp'],
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('closeDownApp', false)
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
.contentBox {
  padding: 40px 0;
}
</style>
