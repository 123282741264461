<template>
  <div class="footerBox">
    <div class="fb_innerBox">
      <el-row :gutter="20" class="ci_innerBox">
        <el-col :span="10">
          <div class="fb_items bg-purple">
            <div class="fbi_tit">友情链接</div>
            <el-row :gutter="20" class="ci_innerBox">
              <el-col :span="8" v-for="(items, index) in friendlyLinks" :key="index">
                <p class="fbi_txt" @click="openLink(items)">
                  {{ items.title }}
                </p>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="cdi_items bg-purple">
            <el-row :gutter="20" class="ci_innerBox">
              <el-col :span="5">
                <div class="fb_items bg-purple">
                  <div class="fbi_tit">相关链接</div>
                  <div v-for="(items, index) in relatedLinks" :key="index">
                    <p class="fbi_txt" @click="openLink(items)">
                      {{ items.title }}
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="fb_items bg-purple">
                  <div class="fbi_tit">关于菏康</div>
                  <div v-for="(items, index) in aboutHk" :key="index">
                    <p class="fbi_txt" @click="openHkLink(index)">
                      {{ items.title }}
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="fb_items bg-purple">
                  <div class="fbi_tit">菏康支持</div>
                  <div v-for="(items, index) in HkSupport" :key="index">
                    <p class="fbi_txt" @click="openMail(index)">
                      {{ items.title }}
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="fb_items bg-purple">
                  <div class="fbi_tit">下载APP</div>
                  <div class="fbi_img">
                    <img src="../assets/imgs/downApp.png" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <div class="copyBox">
        <div class="c_text">@2022 菏康 版权所有</div>
        <div class="c_text c_gongan">
          <a
            target="_blank"
            href="https://beian.miit.gov.cn"
            style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
          >
            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">鲁ICP备2022031898号-1</p>
          </a>
        </div>
        <div class="c_text c_gongan">
          <div class="c_beianImg">
            <img src="../assets/imgs/beian.png" style="float: left" />
          </div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37172702371748"
            style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
          >
            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">
              鲁公网安备 37172702371748号
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      friendlyLinks: [
        {
          title: '中国老龄协会',
          link: 'https://www.cncaprc.gov.cn/'
        },
        {
          title: '中国老龄事业发展基金会',
          link: 'http://www.cadf.org.cn/'
        },
        {
          title: '中国老年学和老年医学学会',
          link: 'http://www.cagg.org.cn/'
        },
        {
          title: '全国离退休人才网',
          link: 'http://www.ltxjob.com/'
        },
        {
          title: '中国老年杂志社',
          link: 'http://www.zgln.cn/'
        },
        {
          title: '中国老龄产业协会',
          link: 'http://www.zgllcy.com/chanye/'
        },
        {
          title: '中国康复医学会',
          link: 'http://www.carm.org.cn/'
        },
        {
          title: '39健康网',
          link: 'http://www.39.net/'
        },
        {
          title: '金婚网',
          link: 'http://www.i60.cn/'
        }
      ],
      relatedLinks: [
        {
          title: '不良信息举报入口',
          link: 'https://www.12377.cn/'
        },
        {
          title: '消费者维权服务',
          link: 'https://www.12315.cn/'
        },
        {
          title: '中国互联网协会',
          link: 'https://www.isc.org.cn/'
        }
      ],
      aboutHk: [
        {
          title: '商务合作',
          link: ''
        },
        {
          title: '获得支持',
          link: ''
        },
        {
          title: '隐私政策',
          link: ''
        }
      ],
      HkSupport: [
        {
          title: '技术：support@zghkyy.com',
          link: ''
        },
        {
          title: '商务：operate@zghkyy.com',
          link: ''
        },
        {
          title: '顾问：180-0135-6892',
          link: ''
        }
      ]
    }
  },
  methods: {
    openLink(data) {
      const w = window.open('about:blank')
      w.location.href = data.link
    },
    openHkLink(data) {
      if (data === 0) {
        this.$router.push({ path: '/busCoo' })
      } else if (data === 1) {
        this.$router.push({ path: '/Support' })
      } else if (data === 2) {
        this.$router.push({ path: '/Privacy' })
      }
    },
    openMail(data) {
      if (data < 2) {
        window.location.href = 'mailto:user@example.com'
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
}
.footerBox {
  width: 100%;
  padding: 40px 0;
  background: #000;
}
.fb_innerBox {
  max-width: 1440px;
  margin: 0 auto;
}
.fbi_tit {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}
.fbi_txt {
  color: #fff;
  opacity: 0.7;
  padding: 4px 0;
}
.fbi_txt:hover {
  opacity: 1;
  cursor: pointer;
}
.fbi_img {
  width: 78px;
  height: 78px;
}
.copyBox {
  border-top: 1px solid #404d4d;
  padding-top: 24px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}
.c_text {
  padding-left: 24px;
}
.c_gongan {
  display: flex;
  align-items: center;
}
.c_beianImg {
  width: 20px;
  height: 20px;
}
</style>
