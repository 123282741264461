const session = {
  setSession: (value) => {
    sessionStorage.setItem('session', JSON.stringify(value))
  },
  getSession: () => {
    return sessionStorage.getItem('session') ? JSON.parse(sessionStorage.getItem('session')) : null
  },
  setData: (name, data) => {
    return typeof data === 'object' ? sessionStorage.setItem([name], JSON.stringify(data)) : sessionStorage.setItem([name], data)
  },
  getPropertyInfo: () => {
    return JSON.parse(sessionStorage.getItem('propertyInfo'))
  },
  getEventInfo: () => {
    return JSON.parse(sessionStorage.getItem('eventInfo'))
  }

}
export default session
