<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              依据中老年人生活所需，菏康家政服务理念是为中老人提供全方的生活照顾和健康护理，以满足他们日常生活中各种需求和提高他们的生活质量，还能够帮助老年人更好地维护自身的健康和幸福。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openProperty">登录商家中心</div>
              <div class="bb_move2" @click="openPropertyRegister">商家入驻申请</div>
              <div class="bb_move3" @click="openUser">用户中心</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 我们可以提供哪些服务 -->
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">我们可以提供哪些服务</div>
        </div>
      </div>
      <div class="servcie_box">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in serviceInfo" :key="index">
            <div class="service_item" v-if="index < 7">
              <div class="si_img">
                <img :src="item.imgUrl" />
              </div>
              <div>
                <div style="font-weight: bold; padding-bottom: 4px">{{ item.title }}</div>
                <p style="opacity: 0.7">{{ item.data }}</p>
              </div>
            </div>
            <div class="service_item" style="background: #333d3d; color: #fff; text-align: left" v-if="index == 7">
              <div style="font-weight: bold; padding-bottom: 4px">
                <span style="font-size: 50px">24+</span>{{ item.title }}
              </div>
              <p style="opacity: 0.7">{{ item.data }}</p>
              <div style="width: 35px">
                <img :src="item.imgUrl" />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="service_data">
        <div v-for="(item, i) in technologyInfo" :key="i">
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
      <!-- 专项服务 -->
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">六重安全保障 360度全方位保护</div>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in privilegeInfo" :key="index">
              <div class="pi_items" style="display: flex; align-items: center">
                <div style="width: 80px; height: 80px">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-left: 16px">
                  <div class="pii_tit">{{ item.title }}</div>
                  <p style="opacity: 0.7">{{ item.data }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 家庭财产 -->
      <div class="privilegeBox" style="background: #fff; margin: 0">
        <div class="ct_innerBox">
          <div class="ci_title">专属顾问</div>
          <p class="ci_p">7x24h服务 了解售前售后顾问联系方式，在需要的时候为您提供更全面的服务。</p>
          <p style="padding-top: 24px">全国统一热线 | 区域专属顾问 | 小区物业中心</p>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      title: '菏康家政专属服务 贴心生活',
      serviceInfo: [
        {
          title: '家政保洁',
          imgUrl: require('@/assets/imgs/home_economics_img1.png'),
          data: '日常保洁、深度保洁'
        },
        {
          title: '整理收纳',
          imgUrl: require('@/assets/imgs/home_economics_img2.png'),
          data: '整屋收纳、公共空间收纳'
        },
        {
          title: '保姆/月嫂',
          imgUrl: require('@/assets/imgs/home_economics_img3.png'),
          data: '照顾老人、护理老人'
        },
        {
          title: '家电维修',
          imgUrl: require('@/assets/imgs/home_economics_img4.png'),
          data: '冰箱、洗衣机、油烟机、空调'
        },
        {
          title: '家电清洗',
          imgUrl: require('@/assets/imgs/home_economics_img5.png'),
          data: '冰箱、洗衣机、油烟机、空调'
        },
        {
          title: '水电维修',
          imgUrl: require('@/assets/imgs/home_economics_img6.png'),
          data: '水暖管爆裂、线路改造/维修'
        },
        {
          title: '除甲醛',
          imgUrl: require('@/assets/imgs/home_economics_img7.png'),
          data: '轻度污染、中度污染、重度污染'
        },
        {
          title: '家政服务项目',
          imgUrl: require('@/assets/imgs/home_economics_img8.png'),
          data: '为中老人提供全方的生活照顾和健康护理'
        }
      ],
      technologyInfo: [
        {
          imgUrl: require('@/assets/imgs/home_economics_img9.png'),
          title: '严格的筛选与认证体系',
          data: '只有经过严格审核后的商家才可以服务于菏康用户，这样更能有效的保障服务质量和提升用户满意度；审核内容包括身份认证、背景调查、服务人员调查和信誉评估等多项审核标准。'
        }
      ],
      privilegeInfo: [
        {
          imgUrl: require('@/assets/imgs/home_economics_img10.png'),
          title: '未消费全额退',
          data: '购买后预约上门，服务更有保障'
        },
        {
          imgUrl: require('@/assets/imgs/home_economics_img11.png'),
          title: '不满意免费重做',
          data: '服务完不满意可申请售后，免费重做'
        },
        {
          imgUrl: require('@/assets/imgs/home_economics_img12.png'),
          title: '售后享菏康补贴金',
          data: '服务不满意申请售后，通过后享补贴金'
        },
        {
          imgUrl: require('@/assets/imgs/home_economics_img13.png'),
          title: '严格的服务认证',
          data: '菏康对商家严格按照服务体系审核'
        },
        {
          imgUrl: require('@/assets/imgs/home_economics_img14.png'),
          title: '服务人员档案',
          data: '对服务人员的身份、健康、技能、信用等认证'
        },
        {
          imgUrl: require('@/assets/imgs/home_economics_img15.png'),
          title: '专属顾问全程跟踪',
          data: '服务过程有任何问题可联系专属顾问解答'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://service.zghkyy.com/'
    },
    openPropertyRegister() {
      const w = window.open('about:blank')
      w.location.href = 'https://zghkyy.com/#/serviceSettled'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/home_economics_img0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right_imgBox {
  width: 660px;
  height: auto;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #09bebe;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}
.bb_move2 {
  background: #dddd24;
  color: #202626;
  padding: 8px 24px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}
.bb_move2:hover {
  background: #e5e55e;
  cursor: pointer;
}
.bb_move3 {
  background: #24dd80;
  color: #fff;
  padding: 8px 24px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}
.bb_move3:hover {
  background: #5ee5a2;
  cursor: pointer;
}
.servcie_box {
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 80px;
}
.service_item {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.025);
  margin-bottom: 20px;
  padding: 30px;
  text-align: center;
}
.si_img {
  width: 98px;
  height: 98px;
  border-radius: 98px;
  margin: 0 auto;
  margin-bottom: 24px;
}
.ci_txtBox {
  max-width: 630px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.content_titBox {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.itemsText {
  text-align: justify;
  padding: 100px 10px 10px 10px;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: auto;
}
.bb_move:hover {
  background: #24dddd;
  cursor: pointer;
}
.hkPlayVideo {
  position: fixed;
  top: 15vh;
  left: 25vw;
  z-index: 9999;
}
.privilegeBox {
  margin-top: 150px;
  background: #f2f4f4;
  text-align: center;
  padding: 80px 40px;
}
.pi_items {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 24px;
}
.pii_tit {
  font-size: 24px;
  text-align: left;
}
</style>
