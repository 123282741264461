<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              均衡饮食和适量运动对于人们十分重要，引导老年人正确认识健康饮食，培养健康饮食习惯；普及食材营养功效，智能推荐菜品及烹饪步骤。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openUser">查看菜谱</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 为什么提倡健康饮食？ -->
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">为什么提倡健康饮食？</div>
          <p class="ci_p">
            均衡饮食和适量运动对于人们十分重要。均衡饮食是指选择多种类和适当分量的食物，以便能提供各种营养素和恰当热量去维持身体组织的生长，增强抵抗力和达致适中的体重。在进食时，应该按照“饮食金字塔”的分量比例进食及每天喝充足水分，以促进健康。均衡饮食使身体正常运作，有助于抵抗疾病，让人时刻感到精力充沛并维持理想体重。如要达致理想体重，最有效及可持续的方法便是保持健康饮食并进行适量运动。多吃煎炸和太甜或太咸的食物可能会引致肥胖，高血压，高胆固醇等，有损人们的健康。
          </p>
        </div>
      </div>
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">中老年人五大饮食误区</div>
          <p class="ci_p">一个完整的个人健康档案，不仅能在第一时间给医生提供最全面的身体信息，还能赢得医生对你的尊重</p>
        </div>
        <div class="content_dataBox">
          <div class="cd_itemsBox">
            <div class="pi_items" v-for="(item, index) in healthyPeople" :key="index">
              <div class="pii_inner" v-if="index % 2 == 0">
                <div class="pii_img">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-top: 24px">
                  <div class="pii_img">
                    <img src="../../assets/imgs/health_img4.png" />
                  </div>
                  <div class="pii_tit">{{ item.title }}</div>
                  <p>{{ item.data }}</p>
                </div>
              </div>
              <div class="pii_inner" v-if="index % 2 != 0" style="margin-top: 60px">
                <div class="pii_img">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-top: 24px">
                  <div class="pii_img">
                    <img src="../../assets/imgs/health_img4.png" />
                  </div>
                  <div class="pii_tit">{{ item.title }}</div>
                  <p>{{ item.data }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">如何才能养成健康饮食习惯</div>
          <p class="ci_p">
            健康档案，指居民身心健康（正常的健康状况、亚健康的疾病预防健康保护促进、非健康的疾病治疗等）过程的规范、科学记录。是以、贯穿整个生命过程、涵盖各种健康相关因素、实现信息多渠道动态收集、满足居民自身需要和健康管理的信息资源。
          </p>
        </div>
      </div>
      <div class="service_data">
        <div v-for="(item, i) in technologyInfo" :key="i">
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" v-if="item.btn == true" @click="openProductDeatail()">了解详情</div>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" v-if="item.btn == true" @click="openProductDeatail()">了解详情</div>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      title: '菏康饮食培养健康饮食习惯',
      healthyPeople: [
        {
          imgUrl: require('@/assets/imgs/foods_img1.png'),
          title: '千金难买老来瘦',
          data: '一些老年人认为“千金难买老来瘦”，对自己体重的下降并不在意，甚至刻意减少体重，这就增加了肌少症的发生风险，严重影响到生活质量，表现为老年人易跌倒、自理能力差、免疫力下降、易生病，甚至缩短寿命。'
        },
        {
          imgUrl: require('@/assets/imgs/foods_img2.png'),
          title: '告别肉类多吃素',
          data: '如果老年人长期吃素，在膳食结构上就容易出现动物性食物摄入不足的情况。而动物性食物是机体获取优质蛋白质的重要来源，富含机体需要的必需氨基酸，如长期摄入不足，会导致营养不足、肌少症甚至营养不良。'
        },
        {
          imgUrl: require('@/assets/imgs/foods_img3.png'),
          title: '担心血糖爱粗粮',
          data: '糖尿病是老年人的常见病、多发病，老年人如未能得到良好的疾病膳食指导，常常会因为担心血糖增高而过度控制饮食。长期过度进食粗粮，也很容易发生消化不良。需要明确的是，良好的血糖控制目的是为身体健康，而过分控制饮食会导致机体发生营养风险。'
        },
        {
          imgUrl: require('@/assets/imgs/foods_img4.png'),
          title: '清淡饮食控血脂',
          data: '合并心脑血管疾病或高脂血症的老年人，常常被嘱咐饮食要低盐低脂，所以为了身体健康，荤腥不吃，油盐不进。清淡饮食是建立在食物多样化的基础上的，要求合理搭配营养，将动物性食物、食用油和盐限制在合理范围内，避免过多使用辛辣调味品。'
        },
        {
          imgUrl: require('@/assets/imgs/foods_img5.png'),
          title: '营养都在汤里面',
          data: '在熬制汤类过程中虽然会溶解出少量的蛋白质，但也会溶解脂肪和嘌呤。为了使味道鲜美，汤里还会加入较多的盐。如果弃肉喝汤，不仅不能摄取足够、优质的营养，还可能摄入过多的嘌呤、脂肪与盐。对于许多合并高脂血症、高尿酸血症或糖尿病的老年患者来说，是不利于健康的。'
        }
      ],
      technologyInfo: [
        {
          lable: 0,
          imgUrl: require('@/assets/imgs/foods_img6.png'),
          title: '丰富的菜谱',
          data: '饮食是一种文化，是物质文化和社会风俗各部分中最能反映民族和地区特色的一个组成部分。菏康饮食收集了包含鲁菜、川菜、粤菜、江苏菜、闽菜、浙江菜、湘菜、徽菜八大菜系，另外包含水果、甜点、茶饮相关饮食方案。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/foods_img7.png'),
          title: '智能推荐',
          data: '按照营养功效为每一种菜品配置标签，并根据中老年人身体状况适当推荐菜品信息，'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/foods_img8.png'),
          title: '食材解读',
          data: '食材是饮食中不可缺少的重要组成部分。它们不仅能提供人体所需的能量，还能为人体提供各种营养物质，如蛋白质、碳水化合物、脂肪、维生素和矿物质等。不同的成分有不同的营养价值。选择适合我们的食材可以让我们的饮食更健康、更美味。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/foods_img9.png'),
          title: '烹饪步骤',
          data: '烹饪是一门需要技巧和经验的艺术，任何一个细节的疏忽都可能导致菜品的失败。掌握关键步骤是制作完美的美食的关键之一；我们为每一道菜都提供了详细的烹饪步骤，按照步骤您也能轻松做美味。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/foods_img10.png'),
          title: '储备食材',
          data: '菏康还为每道菜品提供了食材链接，您可根据菜品详情页面下方查看食材列表，食材均采用正规渠道可安全、放心购买使用。'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    // 关闭视频
    openCloseVideo() {
      this.videoStatus = !this.videoStatus
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/'
    },
    openPropertyRegister() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/#/register'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/foods_img0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #09bebe;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.content_titBox {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.cd_itemsBox {
  display: flex;
  flex-wrap: wrap;
}
.bb_move:hover {
  background: #24dddd;
  cursor: pointer;
}
.bb_playBox:hover {
  cursor: pointer;
  opacity: 1;
}
.privilegeBox {
  margin-top: 150px;
  background: #ebf5f5;
  padding: 80px 40px;
}
.pi_items {
  width: 262px;
  padding: 10px;
}
.pii_inner {
  background: #fff;
  padding: 20px;
  border-radius: 24px;
}
.pii_img {
  max-width: 80px;
  max-height: 80px;
}
.pii_tit {
  font-size: 30px;
  color: #09bebe;
  padding-bottom: 24px;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: 447px;
  border-radius: 24px;
  overflow: hidden;
}
.ci_txtBox {
  max-width: 630px;
}
.service_data {
  padding-bottom: 150px;
}
</style>
