<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="bannerBox">
        <div class="b_t_text">您希望我们提供什么帮助？</div>
      </div>
      <!-- 详细信息 -->
      <el-row class="contentBox">
        <el-col :span="12">
          <div class="grid-content">
            <div class="content_icon">
              <img src="../assets/imgs/my_c.svg" />
            </div>
            <div class="content_tit">新用户接入</div>
            <div class="content_dec">了解有关接入条件、会员资格、服务标准等更多详细信息</div>
            <div class="h_about_link" @click="openNewUser()">获取 >></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content">
            <div class="content_icon">
              <img src="../assets/imgs/help_sell.svg" />
            </div>
            <div class="content_tit">售前售后</div>
            <div class="content_dec">了解售前售后顾问联系方式，在需要的时候为您提供更全面的服务。</div>
            <div class="h_about_link" @click="openSell()">获取 >></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content content_bg2">
            <div class="content_icon">
              <img src="../assets/imgs/technology.svg" />
            </div>
            <div class="content_tit">开发与技术</div>
            <div class="content_dec">遇到账号、产品等问题，您可向菏康技术部反馈。</div>
            <div class="h_about_link" @click="openTechnology()">获取 >></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content content_bg2">
            <div class="content_icon">
              <img src="../assets/imgs/feedback.svg" />
            </div>
            <div class="content_tit">投诉与建议</div>
            <div class="content_dec">您可提交服务质量、功能建议、错误报告等信息，我们在收到信息后会及时处理</div>
            <div class="h_about_link" @click="openFeedback()">获取 >></div>
          </div>
        </el-col>
      </el-row>
      <div class="helpBox">
        <div class="h_about_tit">联系我们</div>
        <div class="h_about_dec">有任何疑问，请联系菏康支持部，通过电话或邮件的方式获取您需要的帮助。</div>
        <div class="h_about_link" @click="openContact()">获取 >></div>
      </div>
    </el-main>
    <!-- 新用户弹出框 -->
    <el-dialog title="新用户接入" :visible.sync="newUserDialog" width="600px">
      <div class="dialog_content">
        <div>
          <div class="c_item_tit">接入条件</div>
          <div>
            由于服务特殊性，您可通过下方电话或邮箱向我们反馈，我们在接到信息后会组织客户经理前去洽谈。感谢您的支持与理解！
          </div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">会员资格</div>
          <div>我们提供多种方案，您可根据自己需求自行选择，详细信息可联系地区顾问获取更多信息。</div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">服务标准</div>
          <div>选择的方案不同，服务标准不同，详细信息可联系地区顾问获取更多信息。</div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">菏康支持部</div>
          <div>我们的办公时间是北京时间周一至周五，09:00 至 18:00。</div>
          <el-row class="contact_box">
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/help_call.svg" />
              </div>
              <div>
                <div class="c_c_number">180-0135-6892</div>
                <div>电话</div>
              </div>
            </el-col>
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/mail.svg" />
              </div>
              <div>
                <div class="c_c_number">support@zghkyy.com</div>
                <div>邮箱</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newUserDialog = false">取 消</el-button>
        <el-button type="primary" @click="newUserDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 售前售后弹出框 -->
    <el-dialog title="售前售后" :visible.sync="sellDialog" width="600px">
      <div class="dialog_content">
        <div class="c_dec_tit">您可以通过以下方式联系我们:</div>
        <div>
          <div class="c_item_tit">售前咨询</div>
          <div>您可以向地区顾问联系方式，也可通过下方电话或邮件联系我们。</div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">售后咨询</div>
          <div>您可通过菏康APP-个人中心-我的顾问中获取地区顾问联系方式获取帮助。</div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">菏康支持部</div>
          <div>我们的办公时间是北京时间周一至周五，09:00 至 18:00。</div>
          <el-row class="contact_box">
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/help_call.svg" />
              </div>
              <div>
                <div class="c_c_number">180-0135-6892</div>
                <div>电话</div>
              </div>
            </el-col>
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/mail.svg" />
              </div>
              <div>
                <div class="c_c_number">support@zghkyy.com</div>
                <div>邮箱</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sellDialog = false">取 消</el-button>
        <el-button type="primary" @click="sellDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 开发与技术弹出框 -->
    <el-dialog title="开发与技术" :visible.sync="technologyDialog" width="600px">
      <div class="dialog_content">
        <div class="c_item_tit">菏康技术部</div>
        <div>我们的办公时间是北京时间周一至周五，09:00 至 18:00。</div>
        <el-row class="contact_box">
          <el-col :span="12" class="c_items">
            <div class="c_call_icon">
              <img src="../assets/imgs/help_call.svg" />
            </div>
            <div>
              <div class="c_c_number">137-1834-5029</div>
              <div>电话</div>
            </div>
          </el-col>
          <el-col :span="12" class="c_items">
            <div class="c_call_icon">
              <img src="../assets/imgs/mail.svg" />
            </div>
            <div>
              <div class="c_c_number">support@zghkyy.com</div>
              <div>邮箱</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="technologyDialog = false">取 消</el-button>
        <el-button type="primary" @click="technologyDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 投诉与建议弹出框 -->
    <el-dialog title="投诉与建议" :visible.sync="feedbackDialog" width="600px">
      <div class="dialog_content">
        <div class="c_dec_tit">
          我们已经在客户端开启了投诉与建议专属通道，您可通过菏康APP-个人中心，“投诉建议”中提交服务质量、功能建议、错误报告等信息，我们在收到信息后会及时处理。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="feedbackDialog = false">取 消</el-button>
        <el-button type="primary" @click="feedbackDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 联系我们弹出框 -->
    <el-dialog title="联系我们" :visible.sync="contactDialog" width="600px">
      <div class="dialog_content">
        <div class="c_dec_tit">您可以通过以下两种方式联系我们:</div>
        <div>
          <div class="c_item_tit">地区顾问</div>
          <div>您可通过菏康APP-个人中心-我的顾问中获取地区顾问联系方式获取帮助。</div>
        </div>
        <div class="c_c_item">
          <div class="c_item_tit">菏康支持部</div>
          <div>我们的办公时间是北京时间周一至周五，09:00 至 18:00。</div>
          <el-row class="contact_box">
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/help_call.svg" />
              </div>
              <div>
                <div class="c_c_number">180-0135-6892</div>
                <div>电话</div>
              </div>
            </el-col>
            <el-col :span="12" class="c_items">
              <div class="c_call_icon">
                <img src="../assets/imgs/mail.svg" />
              </div>
              <div>
                <div class="c_c_number">support@zghkyy.com</div>
                <div>邮箱</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contactDialog = false">取 消</el-button>
        <el-button type="primary" @click="contactDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 页脚 -->
    <footerNavVue />
  </div>
</template>
<script>
import GetPageTitle from '../utils/getPageTitle'
import topNavVue from '../component/topNav.vue'
import footerNavVue from '../component/footerNav.vue'
export default {
  components: {
    topNavVue,
    footerNavVue
  },
  data() {
    return {
      title: '菏康支持部-您希望我们提供什么帮助？',
      contactDialog: false,
      feedbackDialog: false,
      technologyDialog: false,
      sellDialog: false,
      newUserDialog: false
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
  },
  methods: {
    openContact() {
      this.contactDialog = true
    },
    openFeedback() {
      this.feedbackDialog = true
    },
    openTechnology() {
      this.technologyDialog = true
    },
    openSell() {
      this.sellDialog = true
    },
    openNewUser() {
      this.newUserDialog = true
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: '100%';
}
.headerBox {
  position: fixed;
  width: 100%;
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #fff;
}
.n_text {
  font-size: 16px;
  padding-left: 80px;
  color: #fff;
  text-decoration: none;
}
.b_n_text {
  font-size: 16px;
  text-decoration: none;
}
.bannerBox {
  height: 735px;
  background: url('../assets/imgs/support_bg.png') no-repeat;
  background-size: auto 735px;
  background-position: center;
  display: flex;
  justify-content: center;
}
.b_t_text {
  margin-top: 160px;
  padding: 0 40px;
  font-size: 50px;
  font-weight: 500;
  color: #fff;
}
.contentBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 0;
}
.grid-content {
  padding: 40px;
  background-color: #f8fafa;
  margin: 4px;
}
.content_icon {
  width: 44px;
  height: 44px;
}
.content_tit {
  font-size: 32px;
  font-weight: bold;
  text-align: left;
  padding: 24px 0 8px 0;
}
.content_dec {
  font-size: 14px;
  text-align: left;
}
.content_lik {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: #09bebe;
  padding-top: 16px;
}
.helpBox {
  padding-bottom: 60px;
  text-align: center;
}
.h_about_tit {
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 8px;
}
.h_about_link {
  font-size: 14px;
  font-weight: bold;
  color: #09bebe;
  padding-top: 16px;
  cursor: pointer;
}
.h_about_link:hover {
  color: #078b8b;
  text-decoration: underline;
}
img {
  width: 100%;
  height: 100%;
}
.dialog_content {
  color: #333940;
}
.c_dec_tit {
  padding-bottom: 24px;
}
.c_c_item {
  padding-top: 24px;
}
.c_item_tit {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 8px;
}
.contact_box {
  padding-top: 24px;
}
.c_items {
  display: flex;
  align-items: center;
}
.c_call_icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.c_c_number {
  font-size: 20px;
  font-weight: bold;
}
</style>
