<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              菏康资讯专注为中老年用户及养老行业人群提供专业的养老资讯、政策法规、全民反诈等行业快讯,每天及时更新热点新闻、每日一例、菏康早报等最新快讯及突发资讯。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openUser">查看资讯</div>
            </div>
          </div>
        </div>
      </div>
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">十大资讯分类</div>
          <p class="ci_p">
            老龄人群应该加强对时事的重视，多关注国家大事，并将其作为自己日常工作中不可或缺的一部分。这样不仅可以增强自身的安全保障能力，还能够有效地应对各类突发事件。
          </p>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in privilegeInfo" :key="index">
              <div class="pi_items" style="display: flex; align-items: center" v-if="index < 9">
                <div style="width: 80px; height: 80px">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-left: 16px">
                  <div class="pii_tit">{{ item.title }}</div>
                  <p style="opacity: 0.7">{{ item.data }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="8" style="opacity: 0">adsf</el-col>
            <el-col :span="8">
              <div class="pi_items" style="display: flex; align-items: center">
                <div style="width: 80px; height: 80px">
                  <img :src="privilegeInfo[9].imgUrl" />
                </div>
                <div style="padding-left: 16px">
                  <div class="pii_tit">{{ privilegeInfo[9].title }}</div>
                  <p style="opacity: 0.7">{{ privilegeInfo[9].data }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="service_data">
        <div v-for="(item, i) in technologyInfo" :key="i">
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      title: '菏康资讯关注养老时事 关心养老生活',
      technologyInfo: [
        {
          lable: 0,
          imgUrl: require('@/assets/imgs/newsInfo_img11.png'),
          title: '今日热点',
          data: '随着人口老龄化的加剧，养老问题日益成为人们关注的焦点。对于老年人来说,关心时事是一件非常重要的事情。因为时事新闻中蕴藏着丰富的信息和知识，能帮助我们了解国内外的变化趋势、社会现状以及生活中遇到的各种困难等。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/newsInfo_img12.png'),
          title: '每日一例',
          data: '每日分享一例关于全民反诈的典型案例，帮助中老年人正确认识最新行业骗局，提高中老年人反诈意识。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/newsInfo_img13.png'),
          title: '有句口诀',
          data: '防骗顺口溜，安全乐无忧，为全面预防和打击诈骗，最大限度减少用户财产损失，菏康特兹开展反诈土味顺口溜，让反诈宣传深入人心，每次接到疑似诈骗电话脑海里都能想起顺口溜。'
        }
      ],
      privilegeInfo: [
        {
          imgUrl: require('@/assets/imgs/newsInfo_img1.png'),
          title: '热点新闻',
          data: '行业热点'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img2.png'),
          title: '养老视点',
          data: '国家养老、养老资讯、要闻摘要'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img3.png'),
          title: '政策法规',
          data: '国家法规、行业标准、政策解读'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img4.png'),
          title: '养老研究',
          data: '养老战略、养老资产、护理保险、养老模式'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img5.png'),
          title: '社会保障',
          data: '保险、救助、福利、金融'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img6.png'),
          title: '互动养老',
          data: '旅居养老、休闲养老、健康疗养'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img7.png'),
          title: '健康管理',
          data: '生活方式、健康饮食'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img8.png'),
          title: '居家安全',
          data: '消防安全、饮食安全、人身财产'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img9.png'),
          title: '全民反诈',
          data: '热点警示、反诈资讯'
        },
        {
          imgUrl: require('@/assets/imgs/newsInfo_img10.png'),
          title: '菏康热点',
          data: '时事热点新闻'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/newsInfo_img0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #fff;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #202626;
  margin-right: 16px;
}
.service_data {
  margin-bottom: 150px;
}
.ci_txtBox {
  max-width: 630px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: auto;
}
.bb_move:hover {
  background: #24dddd;
  color: #fff;
  cursor: pointer;
}
.privilegeBox {
  margin-top: 150px;
  text-align: center;
  padding: 0 40px;
}
.pi_items {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 24px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.025);
}
.pii_tit {
  font-size: 24px;
  text-align: left;
}
</style>
