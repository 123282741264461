import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BusCoo from '../views/busCoo.vue'
import About from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import Support from '../views/Support.vue'
import UserAppDown from '../views/UserAppDown.vue'
import UserDome from '../views/exDome/UserDome.vue' // 用于活动时用户体验入口
import UserDomeHome from '../views/exDome/UserDomeHome.vue' // 用于活动时用户体验首页
import UserDomeWarn from '../views/exDome/UserDomeWarn.vue' // 预警
import ServiceSettled from '../views/shop/serviceSettled.vue'
import Security from '../views/product/Security.vue'
import Foods from '../views/product/Foods.vue'
import Health from '../views/product/Health.vue'
import HomeEconomics from '../views/product/HomeEconomics.vue'
import NewInfo from '../views/product/NewInfo.vue'
import Shop from '../views/product/Shop.vue'
import UnifyIndex from '../views/logon/index.vue' // 菏康统一登录入口
import UserBenefits from '../views/userBenefits/index.vue' // 用户领取福利
import UserBenefitSresult from '../views/userBenefits/result.vue' // 用户领取福利-结果页
import UserReviewIndex from '../views/userReview/index.vue' // h5用户申请页面  公众号快速申请

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/Home' }, // 默认
  { path: '/Home', component: Home },
  { path: '/busCoo', component: BusCoo },
  { path: '/About', component: About },
  { path: '/Privacy', component: Privacy },
  { path: '/Support', component: Support },
  { path: '/UserAppDown', component: UserAppDown },
  { path: '/UserDome', component: UserDome },
  { path: '/UserDomeHome', name: 'UserDomeHome', component: UserDomeHome },
  { path: '/UserDomeWarn', name: 'UserDomeWarn', component: UserDomeWarn },
  { path: '/ServiceSettled', component: ServiceSettled },
  { path: '/Security', component: Security },
  { path: '/Foods', component: Foods },
  { path: '/Health', component: Health },
  { path: '/HomeEconomics', component: HomeEconomics },
  { path: '/NewInfo', component: NewInfo },
  { path: '/Shop', component: Shop },
  { path: '/UnifyIndex', component: UnifyIndex },
  { path: '/UserBenefits', component: UserBenefits },
  { path: '/UserBenefitSresult', name: 'UserBenefitSresult', component: UserBenefitSresult },
  { path: '/UserReviewIndex', name: 'UserReviewIndex', component: UserReviewIndex }
]

const router = new VueRouter({
  routes
})

export default router
