<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div class="bt_title">{{ title }}</div>
          <p class="bt_p">
            充分利用物联网、云计算、移动互联网等新一代信息通讯技术，提供实时、快捷、高效、物联、智能、科技的生活服务，打造“养老”+“健康”
            的综合服务平台。融合智慧安防、智慧医疗、智慧旅游、智慧出行、便民电商等多样化、多层次的服务体系，
            将运营商、服务商、家庭、 个人连接起来，共同构建安全、舒适、便民的具有现代化、智慧化、易居化的生态环境。
          </p>
          <div class="banner_btnBox">
            <div class="bb_move" @click="openSecurity">统一登录入口</div>
            <div class="bb_playBox" @click="openCloseVideo">
              <div class="bbp_img">
                <img src="../assets/imgs/banner_playBtn.png" />
              </div>
              <span>观看影片</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 数据 -->
      <div class="content_items" style="justify-content: left">
        <div class="ci_imgBox">
          <img src="../assets/imgs/home_c_img0.png" />
        </div>
        <div class="ci_txtBox" style="margin-left: 100px">
          <div class="ci_title">你是否了解过这些数据？</div>
          <p class="ci_p">
            根据第七次全国人口普查显示，全国总人口为14.4亿+，其中，60岁及以上人口为2.6亿+，占比18.7%，65岁及以上人口为1.9亿+，占比13.5%。同比第六次全国人口普查，比重分别上升5.44和4.63个百分点，这意味着我国将进入“深度老龄化社会”。
          </p>
          <div class="ci_data">
            <div class="cid_items">
              <div class="cidi_top">
                <span class="cidit_num">2.6</span>
                <span>亿+</span>
              </div>
              <p>60周岁以上</p>
            </div>
            <div class="cid_items">
              <div class="cidi_top">
                <span class="cidit_num">50</span>
                <span>%</span>
              </div>
              <p>慢性病发病率</p>
            </div>
            <div class="cid_items">
              <div class="cidi_top">
                <span class="cidit_num">1.8</span>
                <span>亿+</span>
              </div>
              <p>慢性病患者</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 问题 -->
      <div class="content_titBox">
        <div class="ci_title">你是否正在面临这些问题？</div>
        <p class="ci_p">
          2022年开始，我国已迎来退休高潮，受多种因素影响，超65%的老人不愿与子女同住，其中97%的人选择居家养老方式。
        </p>
      </div>
      <div class="content_dataBox">
        <el-row :gutter="20" class="ci_innerBox">
          <el-col v-for="(item, index) in faqData" :key="index" :span="6">
            <div class="cdi_items bg-purple">
              <div class="cdii_tit">{{ item.title }}</div>
              <p class="cdii_p">{{ item.data }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 提供服务 -->
      <div class="content_titBox service_bg">
        <div class="sb_inner">
          <div class="ci_title">我们可以提供的服务</div>
          <p class="ci_p">
            公司始终秉持以“诚信、创新、稳定、安全、便捷、高效”的价值观作为行为准则，以人们的健康、安全为企业服务的核心要素，始终秉持“以人为本”的服务理念，充分发挥公司在技术、合作模式的创新能力，为早日建成智慧化生态体系、提高用户生活幸福指数而努力奋斗。
          </p>
        </div>
      </div>
      <!-- 服务明细 -->
      <div class="service_data">
        <div v-for="(item, i) in dataInfo" :key="i">
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" @click="openProductDeatail(item)">了解详情</div>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" @click="openProductDeatail(item)">了解详情</div>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <!-- 播放视频 -->
    <div class="hkPlayVideo" v-if="videoStatus == true">
      <div class="hkp_btn" @click="openCloseVideo">关闭</div>
      <playVideoVue :src="src" :second="3" />
    </div>
  </div>
</template>

<script>
import GetPageTitle from '../utils/getPageTitle'
import topNavVue from '../component/topNav.vue'
import footerNavVue from '../component/footerNav.vue'
import playVideoVue from '../component/playVideo.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    playVideoVue
  },
  data() {
    return {
      videoStatus: false,
      title: '为中老年人提供高品质居家养老生活',
      faqData: [
        {
          title: '我，打工人',
          data: '常年在外打工，缺乏对父母的关心'
        },
        {
          title: '我，独生子',
          data: '独自面临双亲养老问题，心有余而力不足'
        },
        {
          title: '我，生意人',
          data: '常年忙于生意，缺乏对家人关爱'
        },
        {
          title: '我，无业...',
          data: '生活紧迫，面临父母养老问题'
        },
        {
          title: '老人，无人照料',
          data: '父母独自居住，生活无人照料'
        },
        {
          title: '老人，意外摔倒',
          data: '老人行动不便，有意外摔倒风险'
        },
        {
          title: '老人，消防安全',
          data: '房屋老旧，消防隐患较大，老人消防意识薄弱'
        },
        {
          title: '老人，健康状况',
          data: '未能合理管理老人健康档案，不了解老人健康'
        }
      ],
      dataInfo: [
        {
          imgUrl: require('@/assets/imgs/home_c_img2.png'),
          title: '菏康安防',
          data: '菏康安防系统是集物联网、云计算、移动互联网等新一代信息通讯技术背景下而搭建的一套综合应急安全监控中心，优势在于它是以物业或社区作为应急第一监控中心，以智能终端和专属热线为纽带，将每个家庭串联起来，强化居家安防及应急救助服务，重点关注独居、空巢老人的主动关怀和特殊时段的应急服务。同时，菏康作为应急第二监控监督中心，确保突发事件反馈的准确性、及时性，做到立即报警、精准定位等功能，以保证居⺠的人身财产安全。',
          router: '/Security'
        },
        {
          imgUrl: require('@/assets/imgs/home_c_img3.png'),
          title: '菏康家政',
          data: '依据中老年人生活所需，菏康家政服务理念是为中老人提供全方的生活照顾和健康护理，以满足他们日常生活中各种需求和提高他们的生活质量，还能够帮助老年人更好地维护自身的健康和幸福。',
          router: '/HomeEconomics'
        },
        {
          imgUrl: require('@/assets/imgs/home_c_img4.png'),
          title: '菏康健康',
          data: '健康档案是自我保健不可缺少的医学资料，它记录了每个人疾病的发生、发展、治疗和转归的过程。通过比较一段时间来所检查的资料和数据，你可发现自己健康状况的变化，疾病发展趋向、治疗效果等情况，有利于下一步医疗保健的决策。',
          router: '/Health'
        },
        {
          imgUrl: require('@/assets/imgs/home_c_img5.png'),
          title: '菏康饮食',
          data: '均衡饮食和适量运动对于人们十分重要，引导老年人正确认识健康饮食，培养健康饮食习惯；普及食材营养功效，智能推荐菜品及烹饪步骤。',
          router: '/Foods'
        },
        {
          imgUrl: require('@/assets/imgs/home_c_img6.png'),
          title: '菏康好物',
          data: '为满足中老年人购物需求和商品选择难题，菏康精心挑选适用于中老年人日常所需，包含生活用品、养生器材、医疗器械、文化娱乐、时尚服饰、电子产品、保健产品等众多商品种类。',
          router: '/Shop'
        },
        {
          imgUrl: require('@/assets/imgs/home_c_img7.png'),
          title: '菏康资讯',
          data: '菏康资讯专注为中老年用户及养老行业人群提供专业的养老资讯、政策法规、全民反诈等行业快讯,每天及时更新热点新闻、每日一例、菏康早报等最新快讯及突发资讯。',
          router: '/NewInfo'
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    openSecurity() {
      const routeUrl = this.$router.resolve({
        path: '/UnifyIndex'
      })
      window.open(routeUrl.href, '_blank')
    },
    openProductDeatail(data) {
      this.$router.push({ path: data.router })
    },
    // 关闭视频
    openCloseVideo() {
      this.videoStatus = !this.videoStatus
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../assets/imgs/home_banner_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.b_inner {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10% 40px 0;
}
.bt_title {
  font-size: 50px;
  color: #fff;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  color: #fff;
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #09bebe;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}
.bb_playBox {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
}
.bbp_img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.content_items {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ci_imgBox {
  max-width: 920px;
  max-height: 645px;
}
.ci_txtBox {
  max-width: 630px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.ci_data {
  padding-top: 40px;
  display: flex;
  align-items: center;
}
.cid_items {
  margin-right: 80px;
}
.cidit_num {
  font-size: 60px;
  font-weight: bold;
}
.content_titBox {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.cdi_items {
  border-radius: 8px;
  border: 1px solid #e4e8e8;
  padding: 30px;
  margin-bottom: 20px;
}
.cdii_tit {
  font-size: 32px;
  padding-bottom: 12px;
}
.cdii_p {
  opacity: 0.7;
}
.service_bg {
  height: 460px;
  background-image: url('../assets/imgs/home_c_img1.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 0 40px;
}
.sb_inner {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 150px;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: 583px;
  border-radius: 24px;
  overflow: hidden;
}
.cit_btn {
  border-radius: 40px;
  background: #09bebe;
  color: #fff;
  font-size: 14px;
  width: 110px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  margin-top: 40px;
}
.cit_btn:hover,
.bb_move:hover {
  background: #24dddd;
  cursor: pointer;
}
.bb_playBox:hover {
  cursor: pointer;
  opacity: 1;
}
.hkPlayVideo {
  position: fixed;
  top: 15vh;
  left: 25vw;
  z-index: 9999;
}
.hkp_btn {
  position: absolute;
  width: 60px;
  line-height: 40px;
  text-align: center;
  border-radius: 8px;
  background: #cb2f2f;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  z-index: 99999;
  right: 12px;
  top: 12px;
}
.hkp_btn:hover {
  background: #e55e5e;
  cursor: pointer;
}
.service_data {
  padding-bottom: 150px;
}
</style>
