<template>
  <div class="home">
    <el-container>
      <el-main class="mainBox">
        <!-- 头部导航 -->
        <el-header class="headerBox">
          <div class="h_inner">
            <div class="logoBox">
              <img src="../../assets/imgs/logo_white.svg" />
            </div>
          </div>
        </el-header>
        <!-- banner -->
        <div class="bannerBox">
          <div class="b_tit">{{ title.slice(0, 6) }}</div>
          <div class="b_text">{{ title.substr(7) }}</div>
          <div class="b_dec">
            保洁、整理收纳、保姆/月嫂、家电维修、家电清洗、水电维修、洗衣洗鞋、搬家、除甲醛、杀虫灭鼠等专属服务
          </div>
          <div style="padding-top: 24px">
            <el-link
              underline="false"
              class="btn"
              style="background: #fff; color: #333940"
              href="https://service.zghkyy.com/#/register"
              target="_blank"
              >立即合作</el-link
            >
          </div>
        </div>
        <div class="pageItems">
          <div class="items_titBox">
            <div class="items_tit">
              <i class="el-icon-star-on items_icon"></i>合作后，我们帮助您<i class="el-icon-star-on items_icon"></i>
            </div>
          </div>
          <div class="helpInfo">
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/3135679.png" />
              </div>
              <div>
                <div class="gc_itemsTit">提升销售额</div>
                <div class="gc_itemsDec">PLUS超值服务卡，专享折扣、优惠促销吸引新客</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/869432.png" />
              </div>
              <div>
                <div class="gc_itemsTit">提升店铺曝光</div>
                <div class="gc_itemsDec">评价管理，精准营销，精准定位客户群提高店铺曝光</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/2597510.png" />
              </div>
              <div>
                <div class="gc_itemsTit">帮您做好生意</div>
                <div class="gc_itemsDec">精准目标人群，提供7*24小时1对1服务，帮您做好每一单</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageItems">
          <div class="items_titBox">
            <div class="items_tit">
              <i class="el-icon-star-on items_icon"></i>合作流程 <span style="font-size: 16px"> 完成合作只需3步</span
              ><i class="el-icon-star-on items_icon"></i>
            </div>
          </div>
          <div class="helpInfo">
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/3190513.png" />
              </div>
              <div>
                <div class="gc_itemsTit">注册账号</div>
                <div class="gc_itemsDec">手机验证码快速注册</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/7238448.png" />
              </div>
              <div>
                <div class="gc_itemsTit">认领门店，提交资质、确认财务负责人</div>
                <div class="gc_itemsDec">需准备门店信息，营业执照、确定财务负责人及手机号，提交后审核预计1-3工作日</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/4835326.png" />
              </div>
              <div>
                <div class="gc_itemsTit">合作签约</div>
                <div class="gc_itemsDec">确认服务内容、服务地区、服务能力，专属顾问跟踪签约</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageItems">
          <div class="items_titBox">
            <div class="items_tit">
              <i class="el-icon-star-on items_icon"></i>开店要求
              <i class="el-icon-star-on items_icon"></i>
            </div>
          </div>
          <div class="helpInfo">
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/545635.png" />
              </div>
              <div>
                <div class="gc_itemsTit">实体门店</div>
                <div class="gc_itemsDec">拥有实体门店及完善的服务模式</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/10279858.png" />
              </div>
              <div>
                <div class="gc_itemsTit">拥有相关经营资质</div>
                <div class="gc_itemsDec">营业执照、相关许可证/特许证</div>
              </div>
            </div>
            <div class="grid-content bg-purple">
              <div class="gc_itemsImg">
                <img src="../../assets/imgs/1513989.png" />
              </div>
              <div>
                <div class="gc_itemsTit">服务项目在以下范围内</div>
                <div class="gc_itemsDec">
                  保洁、整理收纳、保姆/月嫂、家电维修、家电清洗、水电维修、洗衣洗鞋、搬家、除甲醛、杀虫灭鼠等专属服务
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pageItems">
          <div class="items_titBox">
            <div class="items_tit">
              <i class="el-icon-star-on items_icon"></i>合作常见问题
              <i class="el-icon-star-on items_icon"></i>
            </div>
          </div>
          <div class="faqBox">
            <div><span style="font-weight: bold">Q1. </span> 能否直接安排销售上门/电话确认合作问题？</div>
            <div class="faqDec">
              您完成合作申请后，系统会分配营销顾问。营销顾问会在2个工作日内与您联系洽谈合作细节或短信通知结果。
            </div>
            <div><span style="font-weight: bold">Q1. </span> Q3. 加入菏康需要缴纳相关费用吗？具体价格是多少？</div>
            <div class="faqDec">
              合作签约上线无需缴纳任何费用，后期接单会按照每单收取技术服务费，您完成合作申请后，系统会分配营销顾问与您联系洽谈费用细节。
            </div>
          </div>
        </div>
        <div class="btnBox">
          <el-link class="btn" style="color: #fff" href="https://service.zghkyy.com/#/register" target="_blank"
            >立即合作</el-link
          >
        </div>
      </el-main>
      <el-footer class="footerBox">
        <div class="f_innter">
          <div class="logoBox">
            <img src="../../assets/imgs/logo_cyan.svg" />
          </div>
          <div class="copyright">
            <div class="c_text">@2022 菏康 版权所有</div>
            <div class="c_text">
              <router-link to="/Privacy" class="b_n_text" :underline="false">隐私政策</router-link>
            </div>
            <div class="c_text c_gongan">
              <a
                target="_blank"
                href="https://beian.miit.gov.cn"
                style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
              >
                <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">
                  鲁ICP备2022031898号-1
                </p>
              </a>
            </div>
            <div class="c_text c_gongan">
              <div class="c_beianImg">
                <img src="../../assets/imgs/beian.png" style="float: left" />
              </div>
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37172702371748"
                style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
              >
                <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">
                  鲁公网安备 37172702371748号
                </p>
              </a>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
export default {
  name: 'Home',
  data() {
    return {
      title: '菏康综合商家-家政服务平台'
    }
  },
  mounted() {
    document.title = GetPageTitle(this.title)
  },
  methods: {}
}
</script>
<style scoped>
.home {
  text-align: center;
  font-size: 14px;
  color: #333940;
}
img {
  width: 100%;
  height: 100%;
}
.headerBox {
  position: fixed;
  width: 100%;
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #fff;
}
.n_text {
  font-size: 16px;
  margin-left: 80px;
  color: #fff;
  text-decoration: none;
}
.b_n_text {
  font-size: 16px;
  text-decoration: none;
}
.bannerBox {
  background: url('../../assets/imgs/654654.png') no-repeat;
  background-size: auto;

  padding: 80px 0;
}
.b_tit {
  font-size: 24px;
  color: #fff;
}
.b_text {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  padding: 16px 0;
}
.b_dec {
  font-size: 16px;
  color: #fff;
}
.pageItems {
  padding: 24px 0;
}
.helpInfo {
  display: flex;
  padding: 24px 0;
}
.items_titBox {
  padding: 24px 0;
}
.items_tit {
  font-size: 26px;
  font-weight: bold;
  color: #333940;
}
.items_icon {
  margin: 0 8px;
}
.grid-content {
  width: 33%;
}
.gc_itemsImg {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  margin-bottom: 16px;
}
.gc_itemsTit {
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0;
}
.gc_itemsDec {
  opacity: 0.7;
}
.faqBox {
  padding: 24px 0;
}
.faqDec {
  opacity: 0.7;
  padding: 8px 0;
}
.btnBox {
  padding: 30px;
  border-top-color: #f2f3f4;
  border-top-style: solid;
  border-top-width: 1px;
}
.btn {
  padding: 8px 40px;
  background: #09bebe;
  border-radius: 8px;
  font-size: 18px;
}
.el-link.el-link--default {
  color: #fff;
}
.footerBox {
  background: #f8fafa;
}
a {
  color: #333940;
}
.f_innter {
  height: 60px;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyright {
  display: flex;
  align-items: center;
}
.c_text {
  padding-left: 24px;
}
.c_gongan {
  display: flex;
  align-items: center;
}
.c_beianImg {
  width: 20px;
  height: 20px;
}
img {
  width: 100%;
  height: 100%;
}
</style>
