<template>
  <div class="home">
    <el-container class="pageBg" :style="{ background: pageStatus == true ? '#09be64' : '#DD2424' }">
      <div class="pageContent" v-if="pageStatus == true">
        <div class="scurityImg">
          <img src="../../assets/imgs/security-secure.png" />
        </div>
        <div class="scurityTit">您的所有设备运行正常</div>
      </div>
      <div class="pageContent" v-if="pageStatus == false">
        <div class="scurityImg">
          <img src="../../assets/imgs/security-warn.png" />
        </div>
        <div class="scurityTit">
          {{ eventInfo.deviceName }} - {{ parseToEvent(eventInfo.deviceName, eventInfo.event) }}异常
        </div>
        <div class="scurityTime">报警时间：{{ formatDate(eventInfo.time) }}</div>
        <div>
          <div class="btn btnSuccess" @click="openFamily()">
            <div>紧急联系人</div>
          </div>
          <div class="btn btnWarn" @click="openRelieve()">
            <div>解除报警</div>
          </div>
        </div>
        <div class="bottomBtn">
          <div class="bbtn" @click="openRepair()">
            <div>在线保修</div>
          </div>
          <div class="bbtn">
            <div>关闭震动</div>
          </div>
        </div>
      </div>
    </el-container>
    <!-- 解除报警 -->
    <div class="relieveWarnBox" v-if="relieveWarnStyle == true">
      <div class="rw_content">
        <div class="rwc_titBox">
          <div class="rwc_tit">解除报警</div>
          <div class="rwc_dec" @click="openColse()">关闭</div>
        </div>
        <el-form :model="solveForm" class="demo-ruleForm" label-position="top">
          <el-form-item>
            <el-input type="textarea" :rows="2" placeholder="请输入处理详情" v-model="solveForm.processingRecord">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="openSolveForm('solveForm')">解除</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 紧急联系人 -->
    <div class="familyBox" v-if="familyStyle == true">
      <div class="rw_content">
        <div class="rwc_titBox">
          <div class="rwc_tit">联系人列表</div>
          <div class="rwc_dec" @click="openColseFamily()">关闭</div>
        </div>
        <div class="fb_content">
          <div class="fbc_item" v-for="(item, index) in familyList" :key="index">
            <div class="fbc_item">
              <div class="familyImg">
                <img src="../../assets/imgs/avatar_sir.png" />
              </div>
              <div class="fbci_tit">
                <div class="fbcit_name">{{ item.name }}</div>
                <div class="fbcit_phone">{{ item.phone }}</div>
              </div>
            </div>
            <div class="fbci_right" @click="openCall(item)">
              <img src="../../assets/imgs/call.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 在线报修 -->
    <div class="repairBox" v-if="repairBoxStyle == true">
      <div class="rw_content">
        <div class="rwc_titBox">
          <div class="rwc_tit">在线报修</div>
          <div class="rwc_dec" @click="openColseRepair()">关闭</div>
        </div>
        <el-form :model="reportForm" class="demo-ruleForm" label-position="top">
          <el-form-item>
            <el-input type="textarea" :rows="2" placeholder="请输入报修原因" v-model="reportForm.reason"> </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="openReportForm('reportForm')">立即报修</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 消息提示音 -->
    <audio controls="controls" hidden src="../../assets/music/clew_tone.wav" ref="clewTone"></audio>
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
import session from '@/utils/session'
import { queryDeviceWarningPut } from '@/api/index.js'
export default {
  data() {
    return {
      title: '居家安防报警体验入口',
      propertyInfo: null,
      eventInfo1: null,
      eventInfo: {
        deviceName: '智能燃气探测器',
        event: '报警',
        time: '2022.10.01 12:00:34'
      },
      background: '#DD2424',
      pageStatus: true,
      relieveWarnStyle: false,
      familyStyle: false,
      familyList: [
        {
          name: '代用名',
          phone: '13718345029'
        }
      ],
      repairBoxStyle: false,
      solveForm: {
        //解除警报
        processingRecord: '',
        processingStatus: '1',
        //解决方
        resolver: '自主解决'
      },
      reportForm: {
        //报修内容
        reason: '',
        repair: '1',
        //解决方
        resolver: '自主解决'
      }
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
    this.propertyInfo = session.getPropertyInfo()
    this.eventInfo1 = session.getEventInfo()
    if (this.eventInfo1 != null) {
      this.pageStatus = false
      ;(this.eventInfo.deviceName = this.eventInfo1.deviceInfo.deviceName),
        (this.eventInfo.event = this.eventInfo1.eContent.payload),
        (this.eventInfo.time = this.eventInfo1.eContent.timestamp)
    }
  },
  methods: {
    // 解除报警
    openRelieve() {
      this.relieveWarnStyle = true
    },
    // 关闭弹框
    openColse() {
      this.relieveWarnStyle = false
    },
    // 解除报警
    async openSolveForm() {
      let result = await queryDeviceWarningPut(this.eventInfo1.userDevice.id, this.solveForm)
      if (result.success) {
        if (result.success) {
          this.$message.success('解除报警成功')
          // 播放报警提示音
          this.$refs.clewTone.currentTime = 0
          this.$refs.clewTone.play()
          this.relieveWarnStyle = false
          session.setData('eventInfo', null)
          this.pageStatus = true
        }
      }
    },
    // 打开联系人
    openFamily() {
      this.familyStyle = true
    },
    // 关闭联系人
    openColseFamily() {
      this.familyStyle = false
    },
    // 拨打电话
    openCall(item) {
      window.location.href = `tel:${item.phone}`
    },
    // 打开报修
    openRepair() {
      this.repairBoxStyle = true
    },
    // 关闭报修
    openColseRepair() {
      this.repairBoxStyle = false
    },
    // 报修报警
    async openReportForm() {
      let result = await queryDeviceWarningPut(this.eventInfo1.userDevice.id, this.reportForm)
      if (result.success) {
        if (result.success) {
          this.$message.success('报修成功')
          this.repairBoxStyle = false
        }
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
p {
  padding: 0.25rem;
  margin: 0;
  line-height: 1.6;
}
.pageBg {
  height: 100vh;
  display: block;
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 10px;
  font-size: 62.5%;
}
.pageTitBox {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  padding: 1.87rem 0;
  margin: 0 auto;
}
.scurityImg {
  padding: 2rem;
}
.scurityTit {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  padding-top: 2rem;
}
.scurityTime {
  font-size: 1rem;
  color: #fff;
  text-align: center;
  margin: 1rem 0 2rem 0;
}
.btn {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 2.5rem;
  color: #fff;
  font-size: 1rem;
}
.btnSuccess {
  border: 0.5px solid #fff;
  margin-bottom: 1rem;
}
.btnWarn {
  background: rgba(255, 255, 255, 0.2);
}
.bottomBtn {
  position: fixed;
  bottom: 1rem;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 0.8rem;
}
.bbtn {
  padding: 1rem 2rem;
  margin: 0 1rem;
}
.relieveWarnBox {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.rw_content {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 20rem;
  background: #fff;
  border-radius: 1rem 1rem 0 0;
}
.rwc_titBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.rwc_tit {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.demo-ruleForm {
  padding: 0 1rem;
}
/deep/ .el-textarea__inner {
  height: 9rem;
  font-size: 1rem;
}
/deep/ .el-button {
  font-size: 1rem;
  width: 100%;
  background: #09bebe;
  border: #09bebe;
  height: 3rem;
}
/deep/ .el-button--primary:focus,
.el-button--primary:hover {
  background: #09bebe;
  border: #09bebe;
}
.familyImg {
  width: 3.5rem;
  height: 3.5rem;
}
.fb_content {
  padding: 0 1rem;
}
.fbc_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fbci_tit {
  padding-left: 1rem;
}
.fbcit_name {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.2rem;
}
.fbcit_phone {
  font-size: 0.8rem;
}
.fbci_right {
  width: 2.5rem;
  height: 2.5rem;
}
</style>
