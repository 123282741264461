<template>
  <div class="headerBox">
    <div class="h_inner">
      <div class="logoBox" @click="openHome" style="cursor: pointer">
        <img src="../assets/imgs/logo_white.svg" />
      </div>
      <div class="navTextBox">
        <router-link to="/Home" class="n_text" :underline="false">首页</router-link>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link" style="color: #fff; font-size: 16px; margin-left: 80px; cursor: pointer">
            产品中心<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/Security">菏康安防</el-dropdown-item>
            <el-dropdown-item command="/HomeEconomics">菏康家政</el-dropdown-item>
            <el-dropdown-item command="/Health">菏康健康</el-dropdown-item>
            <el-dropdown-item command="/Foods">菏康饮食</el-dropdown-item>
            <el-dropdown-item command="/Shop">菏康好物</el-dropdown-item>
            <el-dropdown-item command="/NewInfo">菏康资讯</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link to="/busCoo" class="n_text" :underline="false">生态与合作</router-link>
        <router-link to="/Support" class="n_text" :underline="false">获得支持</router-link>
        <router-link to="/About" class="n_text" :underline="false">关于我们</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },
  methods: {
    openHome() {
      this.$router.push({ path: '/Home' })
    },
    handleCommand(command) {
      this.$router.push({ path: command })
    }
  }
}
</script>
<style scoped>
.home {
  text-align: center;
}
img {
  width: 100%;
  height: 100%;
}
.headerBox {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #333;
}
.n_text {
  font-size: 16px;
  margin-left: 80px;
  text-decoration: none;
}
.n_text:hover {
  font-weight: bold;
}
.b_n_text {
  font-size: 16px;
  text-decoration: none;
}
.el-dropdown-link:hover {
  font-weight: bold;
}
</style>
