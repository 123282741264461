<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="bannerBox">
        <div class="bb_content">
          <div class="b_text">
            <div>
              <div class="bt_tit">欢迎加入菏康合作伙伴网络</div>
              <p>一起聚焦物联网、云计算、移动通讯，提供实时、快捷、高效、物联、智能、科技的居家养老服务</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 详细信息 -->
      <div class="contentBox">
        <div class="c_items">
          <div class="ci_tit">合作伙伴申请流程</div>
          <el-row :gutter="20" class="ci_innerBox">
            <el-col :span="8" v-for="(item, index) in lcData" :key="index">
              <div class="cdi_items">
                <div class="cdii_num">{{ item.num }}</div>
                <div class="cdii_tit">{{ item.tit }}</div>
                <p class="cdii_p">{{ item.dec }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="c_items2">
          <div class="c_items2_con">
            <div class="ci_tit2">选择伙伴发展路径，快速开展业务</div>
            <el-row :gutter="20">
              <el-col :span="6" v-for="(item, index) in hbData" :key="index">
                <div class="cdi_items bg-purple">
                  <div class="cdi_img">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="cdii_tit">{{ item.tit }}</div>
                  <p class="cdii_p">{{ item.dec }}</p>
                  <p class="cdii_p" style="padding: 16px 0">{{ item.content }}</p>
                  <el-row :gutter="20">
                    <el-col :span="index == 1 || index == 3 ? 24 : 12" v-for="(v, i) in item.data" :key="i">
                      <div class="cic_tit">{{ v.tit }}</div>
                    </el-col>
                  </el-row>
                  <div class="ci_btn" @click="openSearch(index)">
                    <div class="cib_tit">立即加入</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="c_items">
          <div class="ci_tit">专业支持服务，加速业务能力提升</div>
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in fuData" :key="index">
              <div class="cit_imgBox">
                <img :src="item.img" alt="" />
              </div>
              <div>
                <div class="cdii_tit">{{ item.tit }}</div>
                <p class="cdii_p">{{ item.dec }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="c_items2 c_items4">
          <div class="c_items2_con">
            <div class="ci_tit2">合作案例</div>
            <div class="ci_content">
              <el-carousel height="440px" direction="vertical" :autoplay="true">
                <el-carousel-item v-for="(item, index) in hzData" :key="index">
                  <el-row :gutter="20" class="cic_inner">
                    <el-col :span="12">
                      <div class="cdii_tit">{{ item.tit }}</div>
                      <p>{{ item.dec }}</p>
                      <!-- <div class="ci_btn2">
                        <div class="cib_tit" style="color: #fff">查看详情</div>
                      </div> -->
                    </el-col>
                    <el-col :span="12">
                      <div class="citem4_imgBox">
                        <img :src="item.img" alt="" v-if="item.img != null" />
                        <div v-if="item.img == null" class="citem4_dec">图片加载异常</div>
                      </div>
                    </el-col>
                  </el-row>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <!-- 表单 -->
      <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <div class="formBox">
          <div class="form_inner">
            <div class="content_tit">提交意向申请</div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="联系姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入联系人姓名"></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入联系人电话"></el-input>
              </el-form-item>
              <el-form-item label="合作内容" prop="content">
                <el-input type="textarea" v-model="form.content" placeholder="请简介说明合作事宜"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
  </div>
</template>

<script>
import GetPageTitle from '../utils/getPageTitle'
import topNavVue from '../component/topNav.vue'
import footerNavVue from '../component/footerNav.vue'
import { queryBusCoo } from '@/api/index.js'
export default {
  name: 'Home',
  components: {
    topNavVue,
    footerNavVue
  },
  data() {
    return {
      title: '菏康生态与合作',
      activeName: '1',
      form: {
        index: null,
        name: '',
        mobile: '',
        content: ''
      },
      lcData: [
        {
          num: '01',
          tit: '提交申请',
          dec: '点击下面「提交合作伙伴申请」或「立即加入」按钮，填写合作申请表并提交'
        },
        {
          num: '02',
          tit: '验证审核',
          dec: '收到您申请后，我们将在 10 个工作日内审核完毕，请您耐心等待'
        },
        {
          num: '03',
          tit: '沟通合作',
          dec: '如果您提交的资源通过审核后，菏康平台人员将会联系您，协商合作事项'
        }
      ],
      hbData: [
        {
          tit: '社区伙伴发展路径',
          dec: '点击下面「提交合作伙伴申请」或「立即加入」按钮，填写合作申请表并提交',
          content: '合作内容',
          img: require('../assets/imgs/shequ_icon.png'),
          data: [
            {
              tit: '· 社区呼叫中心'
            },
            {
              tit: '· 社区党建中心'
            },
            {
              tit: '· 社区监控中心'
            },
            {
              tit: '· 社区文化中心'
            },
            {
              tit: '· 社区护理服务中心'
            },
            {
              tit: '· 社区理疗中心'
            },
            {
              tit: '· 社区健康管理中心'
            },
            {
              tit: '· 社区助浴中心'
            },
            {
              tit: '· 社区家政服务中心'
            },
            {
              tit: '· 社区辅具中心(4S)'
            }
          ]
        },
        {
          tit: '物业伙伴发展路径',
          dec: '点击下面「提交合作伙伴申请」或「立即加入」按钮，填写合作申请表并提交',
          content: '合作内容',
          img: require('../assets/imgs/wuye_icon.png'),
          data: [
            {
              tit: '· 智慧社区养老服务站'
            },
            {
              tit: '· 市场开拓，合作共赢'
            }
          ]
        },
        {
          tit: '服务商伙伴发展路径',
          dec: '点击下面「提交合作伙伴申请」或「立即加入」按钮，填写合作申请表并提交',
          content: '合作内容',
          img: require('../assets/imgs/fuwushang_icon.png'),
          data: [
            {
              tit: '· 护理服务'
            },
            {
              tit: '· 健康管理'
            },
            {
              tit: '· 家政服务'
            },
            {
              tit: '· 理疗服务'
            },
            {
              tit: '· 智能设备'
            }
          ]
        },
        {
          tit: '合作伙伴发展路径',
          dec: '点击下面「提交合作伙伴申请」或「立即加入」按钮，填写合作申请表并提交',
          content: '合作内容',
          img: require('../assets/imgs/hezuohuoban_icon.png'),
          data: [
            {
              tit: '· 行业开拓，方案整合'
            },
            {
              tit: '· 地区区域负责人'
            }
          ]
        }
      ],
      fuData: [
        {
          tit: '合作前支持',
          dec: '明确合作范围，指定合理的合作计划',
          img: require('../assets/imgs/fuwu_img1.png')
        },
        {
          tit: '合作后支持',
          dec: '助力快速获取业务支持',
          img: require('../assets/imgs/fuwu_img2.png')
        },
        {
          tit: '7x24h专业服务',
          dec: '1对1咨询专属顾问',
          img: require('../assets/imgs/fuwu_img3.png')
        }
      ],
      hzData: [
        {
          tit: '青塔智慧社区养老助残服务站',
          dec: '智慧社区虚拟养老院',
          img: require('../assets/imgs/qtsq.png')
        },
        {
          tit: '廊坊华油社区养老助残服务站',
          dec: '智慧社区虚拟养老院',
          img: require('../assets/imgs/lfhy.png')
        },
        {
          tit: '房山智慧社区养老助残服务站',
          dec: '智慧社区虚拟养老院',
          img: null
        }
      ],
      dialogVisible: false
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    openSearch(item) {
      this.form.index = item
      this.dialogVisible = true
    },
    async onSubmit() {
      const form = this.form
      if (form.index === 0) {
        form.content = '社区伙伴-' + this.form.content
      } else if (form.index === 1) {
        form.content = '物业伙伴-' + this.form.content
      } else if (form.index === 2) {
        form.content = '服务商伙伴-' + this.form.content
      } else if (form.index === 3) {
        form.content = '合作伙伴-' + this.form.content
      }
      // const res = await request.post(SYSWEBBUSCOO, form)
      const res = await queryBusCoo(form)
      if (res.success) {
        this.form.index = null
        this.form.content = null
        this.form.name = null
        this.form.mobile = null
        this.$message({
          message: '我们已经收到您的邀请，会尽快与您取得联系。',
          type: 'success'
        })
        this.dialogVisible = false
      } else {
        this.$message.error('系统错误，请稍后重试')
      }
    }
  }
}
</script>
<style scoped>
p {
  line-height: 20px;
}
img {
  width: 100%;
  height: '100%';
}
.headerBox {
  position: fixed;
  width: 100%;
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #fff;
}
.n_text {
  font-size: 16px;
  padding-left: 80px;
  color: #fff;
  text-decoration: none;
}
.bannerBox {
  height: 500px;
  background: url('../assets/imgs/banner2.png') no-repeat;
  background-size: 100% 500px;
}
.bb_content {
  max-width: 1440px;
  margin: 0 auto;
  height: 500px;
  display: flex;
}
.b_text {
  display: flex;
  align-items: center;
}
.bt_tit {
  font-size: 50px;
  padding-bottom: 24px;
}
.c_items {
  max-width: 1440px;
  margin: 0 auto;
  padding: 150px 0;
}
.c_items2 {
  background: url('../assets/imgs/c_item2.png') no-repeat;
  background-size: 100% 1018px;
  padding: 150px 0;
}
.ci_tit {
  font-size: 50px;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}
.ci_tit2 {
  font-size: 50px;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  color: #fff;
}
.c_items2_con {
  max-width: 1440px;
  margin: 0 auto;
}
.content_tit {
  font-size: 24px;
  color: #202626;
  margin-bottom: 40px;
}
.cdii_tit {
  font-size: 24px;
  font-weight: 500;
  padding: 16px 0;
}
.cdi_items {
  background: #f6fafa;
  border-radius: 16px;
  padding: 40px;
  color: #078b8b;
}
.bg-purple {
  padding: 30px;
  height: 420px;
}
.cdii_num {
  font-size: 50px;
}
.cdii_p {
  color: #202626;
}
.cic_tit {
  color: #202626;
  font-size: 14px;
  line-height: 28px;
}
.cdi_img {
  width: 60px;
  height: 60px;
  background: #f2f4f4;
}
.cit_imgBox {
  width: 100%;
  height: 260px;
  background: #f6fafa;
  border-radius: 16px;
}
.ci_btn {
  margin-top: 40px;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  border: 1px solid #202626;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  color: #202626;
  cursor: pointer;
}
.ci_btn:hover {
  background: #078b8b;
  color: #fff !important;
  border: 1px solid #078b8b;
}
.ci_btn2 {
  margin-top: 40px;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
}
.cib_tit {
  font-size: 14px;
}
.c_items4 {
  background: url('../assets/imgs/hz_bg.png') no-repeat;
  background-size: 100% 1018px;
  padding: 150px 0;
}
.ci_content {
  color: #fff;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.citem4_imgBox {
  background: #fff;
  height: 440px;
}
.cic_inner {
  display: flex;
  align-items: center;
}
.citem4_dec {
  color: #202626;
  opacity: 0.6;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formBox {
  width: 100%;
}
.form_inner {
  max-width: 1440px;
  margin: 0 auto;
  text-align: start;
}
img {
  width: 100%;
  height: 100%;
}
</style>
