<template>
  <div class="home">
    <div class="tit icon"><i class="el-icon-success"></i></div>
    <div class="tit">领取成功！请联系工作人员领取福利</div>
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
export default {
  data() {
    return {
      title: '菏康安防新春送福活动'
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = this.title
  },
  methods: {}
}
</script>
<style scoped>
.home {
  padding-top: 5rem;
}
.tit {
  width: 100vw;
  font-size: 1rem;
  text-align: center;
}
.icon {
  font-size: 10rem;
  color: #67c23a;
}
</style>
