<template>
  <div class="home">
    <el-container class="pageBg">
      <div class="pageTitBox">
        <div class="pageTitLogo">
          <img src="../assets/imgs/downLogo.svg" />
        </div>
        <div class="pageTit linear-gradient">下载菏康APP</div>
        <div class="pageDec linear-gradient">居家必备 家政、安防一次搞定</div>
      </div>
      <div class="downImgBox">
        <img src="../assets/imgs/user_app_down_img.png" />
      </div>
      <div class="downBox">
        <a class="iphoneBox" style="color: #333" href="https://apps.apple.com/cn/app/%E8%8F%8F%E5%BA%B7/id6444652089">
          <div class="iphone_logo">
            <img src="../assets/imgs/iphoen_logo.svg" />
          </div>
          <div class="downBtnTxt">iPhone下载</div>
        </a>
        <a class="androidBox" style="color: #333" href="https://www.pgyer.com/nfTU">
          <div class="android_logo">
            <img src="../assets/imgs/android_logo.svg" />
          </div>
          <div class="downBtnTxt">Android下载</div>
        </a>
      </div>
      <div class="textBox linear-gradient">特色服务</div>
      <div>
        <p class="textTit linear-gradient">1、智慧安防安全又放心</p>
        <p class="textDec linear-gradient">一键呼救： 紧急情况精准定位，缩短救援时间；</p>
        <p class="textDec linear-gradient">燃气报警：NB-IOT物联网报警设备，支持报警主动关闭燃气阀门，远程关闭阀门；</p>
        <p class="textDec linear-gradient">
          烟雾报警：NB-IOT物联网报警设备，支持报警主动关闭关联燃气阀门，降低重大火灾隐患；
        </p>
        <p class="textDec linear-gradient">警情溯源：突发状况精准定位，明细追溯责任；</p>
        <p class="textDec linear-gradient">设备检测与维修：定期组织上门对设备检测与维修，保证设备正常运行；</p>
        <p class="textDec linear-gradient">
          保障服务：赠送家庭财产保险，保额最高达500万，千万设备保险，突发状况降低财产损失；
        </p>
        <p class="textDec linear-gradient">
          专属顾问：如果您在使用中有任何问题，都可以联系地区顾问获取帮助，如果不满意可向我们投诉。
        </p>
        <p class="textDec linear-gradient">7x24小时监控：用户、物业、菏康三大中心同步监控；</p>
        <p class="textTit linear-gradient">2、家政专属服务，贴心生活</p>
        <p class="textDec linear-gradient">
          服务项目：保洁、整理收纳、保姆/月嫂、家电维修、家电清洗、水电维修、洗衣洗鞋、搬家、除甲醛、杀虫灭鼠等专属服务；
        </p>
        <p class="textDec linear-gradient">PLUS超值服务卡：专属折扣，省多少付多少，不满意重做同享菏康服务补贴金；</p>
        <p class="textDec linear-gradient">
          售后无忧：如果您购买了服务且服务还未开始，均可无条件退款，如对本次服务不满意可申请售后，免费重做同享菏康服务补贴金。
        </p>
      </div>
      <div class="textBox linear-gradient">超值福利</div>
      <div>
        <p class="textTit linear-gradient">1、新用户开通智慧安防服务</p>
        <p class="textDec linear-gradient">
          代金券：新用户申请成功即赠送代金券，可抵扣本次服务费「代金券面额以APP内实际金额为准」；
        </p>
        <p class="textDec linear-gradient">
          补贴金：对于未接入的小区用户申请时，享延时安装补贴金「可叠加其它优惠使用」；
        </p>
        <p class="textDec linear-gradient">
          烟雾报警：NB-IOT物联网报警设备，支持报警主动关闭关联燃气阀门，降低重大火灾隐患；
        </p>
        <p class="textDec linear-gradient">赠送PLUS超值服务卡：有效期同安防服务期限。</p>
        <p class="textTit linear-gradient">2、其余福利</p>
        <p class="textDec linear-gradient">新用户可免费使用30天PLUS超值服务卡；</p>
        <p class="textDec linear-gradient">邀请好友注册可享丰厚佣金奖励「具体规则以APP内为准」。</p>
      </div>
      <div class="textBox linear-gradient">关于菏康</div>
      <div>
        <p class="textDec linear-gradient">
          菏康立足于“提供优质高效养老服务、构建智慧养老生态体系、提高用户生活幸福指数”的经营理念，以“守护人们的生命财产安全”
          为使命。
          充分利用物联网、云计算、移动互联网等新一代信息通讯技术，提供实时、快捷、高效、物联、智能、科技的生活服务，打造“养老”+“健康”
          的综合服务平台。
        </p>
        <p class="textDec linear-gradient">
          通过菏康用户服务中心、菏康物业服务中心、菏康商家服务中心、菏康运营服务中心以及我们现在或将来还可能不时提供的其他程序、软件、客户端或其他类似产品提供包括智慧养老、智慧安防、智慧医养、便民电商、智慧出行、智慧旅游等服务，为人们提供安全、舒适、便民的现代化、智慧化、易居化生态环境。
        </p>
      </div>
      <div class="textBox linear-gradient">用户帮助</div>
      <div>
        <p class="textDec linear-gradient">感谢您使用菏康用户手机客户端，使用中有任何问题可通过以下方式查询解决：</p>
        <p class="textDec linear-gradient">专属顾问：我的 -- 安防 -- 我的顾问</p>
        <p class="textDec linear-gradient">新手攻略：我的 -- 新手攻略</p>
      </div>
    </el-container>
  </div>
</template>
<script>
import GetPageTitle from '../utils/getPageTitle'
export default {
  data() {
    return {
      title: '下载菏康APP-居家必备 家政、安防一次搞定'
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
p {
  padding: 0.25rem;
  margin: 0;
  line-height: 1.6;
}
.pageBg {
  background: #078b8b;
  height: 100%;
  display: block;
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 10px;
  font-size: 62.5%;
}
.pageTitBox {
  padding: 1.87rem 0;
  margin: 0 auto;
}
.pageTitLogo {
  width: 5.37rem;
  height: auto;
  margin: 0 auto;
}
.pageTit {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
}
.linear-gradient {
  background-image: linear-gradient(#ffffff, #d9fff1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pageDec {
  font-size: 0.75rem;
  text-align: center;
  padding-top: 0.25rem;
}
.downBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.87rem 0;
}
.iphoneBox,
.androidBox {
  width: 48%;
  height: 2.43rem;
  background: #ffffff;
  border-radius: 1.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.35rem 0.35rem -0.12rem rgba(6, 115, 115, 0.5);
}
.iphone_logo,
.android_logo {
  width: 1rem;
  height: 1rem;
}
.downBtnTxt {
  font-size: 0.93rem;
  font-weight: bold;
  padding-left: 0.4rem;
}
.textBox {
  font-size: 1.56rem;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem;
}
.textTit {
  font-size: 0.81rem;
  font-weight: bold;
  margin-top: 1rem;
}
.textDec {
  font-size: 0.81rem;
  text-align: justify;
}
</style>
