<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="bannerBox">
        <div class="b_t_text">诚信服务、规范经营</div>
      </div>
      <!-- 详细信息 -->
      <div class="contentBox">
        <div class="content_con">
          <div class="content_tit">{{ title }}</div>
          <div class="content_dec">
            菏康
            立足于“提供优质高效养老服务、构建智慧养老生态体系、提高用户生活幸福指数”的经营理念，以“守护人们的生命财产安全”
            为使命。
            充分利用物联网、云计算、移动互联网等新一代信息通讯技术，提供实时、快捷、高效、物联、智能、科技的生活服务，打造“养老”+“健康”
            的综合服务平台。融合智慧安防、智慧医疗、智慧旅游、智慧出行、便民电商等多样化、多层次的服务体系，
            将运营商、服务商、家庭、 个人连接起来，共同构建安全、舒适、便民的具有现代化、智慧化、易居化的生态环境。
          </div>
          <div class="content_tit2">愿景</div>
          <div class="content_dec">菏康，让家更安全</div>
          <div class="content_tit2">使命</div>
          <div class="content_dec">守护人们的生命财产安全</div>
          <div class="content_tit2">价值观</div>
          <div class="content_dec">诚信、创新、稳定、安全、便捷、高效</div>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
  </div>
</template>
<script>
import GetPageTitle from '../utils/getPageTitle'
import topNavVue from '../component/topNav.vue'
import footerNavVue from '../component/footerNav.vue'
export default {
  components: {
    topNavVue,
    footerNavVue
  },
  data() {
    return {
      title: '菏泽菏康养老服务有限公司'
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: '100%';
}
.headerBox {
  position: fixed;
  width: 100%;
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #fff;
}
.n_text {
  font-size: 16px;
  padding-left: 80px;
  color: #fff;
  text-decoration: none;
}
.b_n_text {
  font-size: 16px;
  text-decoration: none;
}
.bannerBox {
  height: 735px;
  background: url('../assets/imgs/aboutbg.png') no-repeat;
  background-size: auto 735px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b_t_text {
  padding: 0 40px;
  font-size: 50px;
  font-weight: 500;
  color: #fff;
}
.contentBox {
  width: 100%;
}
.content_con {
  max-width: 1440px;
  margin: 0 auto;
  text-align: start;
  padding: 40px 0;
}
.content_tit {
  font-size: 40px;
  font-weight: 500;
  color: #202626;
  margin-bottom: 24px;
}
.content_tit2 {
  font-size: 32px;
  font-weight: 500;
  color: #202626;
  margin-bottom: 24px;
}
.content_dec {
  padding-bottom: 24px;
}
img {
  width: 100%;
  height: 100%;
}
</style>
