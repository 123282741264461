<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-main class="mainBox">
      <!-- banner -->
      <div class="banner_box">
        <div class="b_inner">
          <div>
            <div class="bt_title">{{ title.slice(0, 4) }}<br />{{ title.substr(4) }}</div>
            <p class="bt_p">
              健康档案是自我保健不可缺少的医学资料，它记录了每个人疾病的发生、发展、治疗和转归的过程。通过比较一段时间来所检查的资料和数据，你可发现自己健康状况的变化，疾病发展趋向、治疗效果等情况，有利于下一步医疗保健的决策。
            </p>
            <div class="banner_btnBox">
              <div class="bb_move" @click="openUser">我的健康档案</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 你是否了解健康档案？ -->
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">你是否了解健康档案？</div>
          <p class="ci_p">
            健康档案，指居民身心健康（正常的健康状况、亚健康的疾病预防健康保护促进、非健康的疾病治疗等）过程的规范、科学记录。是以、贯穿整个生命过程、涵盖各种健康相关因素、实现信息多渠道动态收集、满足居民自身需要和健康管理的信息资源。
          </p>
        </div>
      </div>
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">健康档案的作用意义</div>
          <p class="ci_p">
            健康档案是自我保健不可缺少的医学资料，它记录了每个人疾病的发生、发展、治疗和转归的过程。通过比较一段时间来所检查的资料和数据，你可发现自己健康状况的变化，疾病发展趋向、治疗效果等情况，有利于下一步医疗保健的决策。如高血压病人根据血压值的变化，就能较好掌握控制血压的方法；糖尿病病人可了解血糖变化的规律，使病人对自己的病情变化做到心中有数。有些病人对某种药物接连发生过敏反应，这一情况记入健康档案，就可提示再就医时避免用这种药物。
          </p>
          <p class="ci_p" style="padding-top: 24px">
            带着健康档案去医院看病，给医生诊治疾病也带来很大的方便，医生看到有些检查已经做过，就可避免重复。不仅为病人节约了医疗开支，还减少了病人因检查所带来的麻烦和痛苦，而且为病人的早期诊断、早期治疗提供了条件。万一病人在某些场合发生意外，也可根据健康档案资料判断病情，给予及时正确的处理。
          </p>
        </div>
      </div>
      <div class="privilegeBox">
        <div class="ct_innerBox">
          <div class="ci_title">健康档案重点人群</div>
          <p class="ci_p">一个完整的个人健康档案，不仅能在第一时间给医生提供最全面的身体信息，还能赢得医生对你的尊重</p>
        </div>
        <div class="content_dataBox">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in healthyPeople" :key="index">
              <div class="pi_items" v-if="index % 2 == 0">
                <div class="pii_img">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-top: 24px">
                  <div class="pii_img">
                    <img src="../../assets/imgs/health_img4.png" />
                  </div>
                  <div class="pii_tit">{{ item.title }}</div>
                  <p>{{ item.data }}</p>
                </div>
              </div>
              <div class="pi_items" v-if="index % 2 != 0" style="margin-top: 60px">
                <div class="pii_img">
                  <img :src="item.imgUrl" />
                </div>
                <div style="padding-top: 24px">
                  <div class="pii_img">
                    <img src="../../assets/imgs/health_img4.png" />
                  </div>
                  <div class="pii_tit">{{ item.title }}</div>
                  <p>{{ item.data }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="content_titBox">
        <div class="ct_innerBox">
          <div class="ci_title">如何建立健康档案</div>
          <p class="ci_p">
            健康档案，指居民身心健康（正常的健康状况、亚健康的疾病预防健康保护促进、非健康的疾病治疗等）过程的规范、科学记录。是以、贯穿整个生命过程、涵盖各种健康相关因素、实现信息多渠道动态收集、满足居民自身需要和健康管理的信息资源。
          </p>
        </div>
      </div>
      <div class="service_data">
        <div v-for="(item, i) in technologyInfo" :key="i">
          <div class="sd_items" v-if="i % 2 == 0">
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
            <div class="ci_txtBox" style="padding-left: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" v-if="item.btn == true" @click="openProductDeatail()">了解详情</div>
            </div>
          </div>
          <div class="sd_items" v-if="i % 2 != 0">
            <div class="ci_txtBox" style="padding-right: 80px">
              <div class="ci_title">{{ item.title }}</div>
              <p class="ci_p">
                {{ item.data }}
              </p>
              <div class="cit_btn" v-if="item.btn == true" @click="openProductDeatail()">了解详情</div>
            </div>
            <div class="sd_imgBox">
              <img :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <!-- 页脚 -->
    <footerNavVue />
    <downAppVue :downApp="downApp" @closeDownApp="closeDownApp" />
  </div>
</template>

<script>
import GetPageTitle from '../../utils/getPageTitle'
import topNavVue from '../../component/topNav.vue'
import footerNavVue from '../../component/footerNav.vue'
import downAppVue from '../../component/downApp.vue'
export default {
  components: {
    topNavVue,
    footerNavVue,
    downAppVue
  },
  data() {
    return {
      downApp: false,
      title: '菏康健康帮助中老年人管理健康档案',
      healthyPeople: [
        {
          imgUrl: require('@/assets/imgs/health_img1.png'),
          title: '60岁+老年人',
          data: '由于身体各系统逐渐退化，老年人免疫功能下降，容易患上各类疾病，如心血管疾病、呼吸系统疾病、肾脏疾病等；第一时间将老人疾病史、用药史、体检报告，生活习惯等信息提供给医生；医生团队也能够第一时间采取最有效、最精准的方式进行诊断。'
        },
        {
          imgUrl: require('@/assets/imgs/health_img2.png'),
          title: '慢性病患者',
          data: '现代社会中，越来越多的人因为不良生活方式的长期持续积累而生病，患有高血压、糖尿病、肝病、哮喘等慢性病的人因病情特殊，对外界刺激的抵抗力较弱，容易引起病情加重或并发症，对于慢性病患者建立健康档案养成良好的生活、饮食习惯，健康档案是疾病预防最好的参考。'
        },
        {
          imgUrl: require('@/assets/imgs/health_img3.png'),
          title: '高危人群',
          data: '高危人群是指容易患上某些疾病或病情较重的人群；高危人群需要注意对身体的保健，定期进行体检和治疗，避免接触有害物质和细菌病毒，注重饮食和运动，避免不良生活习惯，保持良好的心态。建立健康档案，指导健康管理和防病预防。'
        }
      ],
      technologyInfo: [
        {
          lable: 0,
          imgUrl: require('@/assets/imgs/health_img5.png'),
          title: '创建家庭组',
          data: '家庭组是为了更好的帮助用户随时关注家庭成员健康信息而设置的群组方式。用户可根据自己需求创建和关联家庭组。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img6.png'),
          title: '成员健康档案',
          data: '成员健康档案是菏康健康重要组成部分，用户可根据页面目录填写健康数据，家庭组的成员可以随时查看成员健康档案，长期完整的健康信息有利于对成员健康作出正确的分析与预测。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img7.png'),
          title: '健康预警',
          data: '将成员健康信息依据物联网大数据算法比对分析成员各项患病风险，对于超出阈值的健康项目，第一时间发现健康异常及自动预警机制，及时的通知家庭组成员预警内容。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img8.png'),
          title: '改善计划',
          data: '正确有效的计划可以帮组用户养成更健康的生活习惯。菏康提供多项针对健康的改善计划，用户可以根据自身需求选择参与计划；当用户健康档案出现预警时系统将自动提醒用户参与改善计划。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img9.png'),
          title: '用药管理',
          data: '老年人作为高龄群体，服药量高，用药风险也较大，如果没有合理的管理，老年人用药容易出现药品副作用、药物过量、浪费药物等现象，严重影响老年人的健康。因此，菏康用药管理的作用就是为了帮助成员合理对用药管理，功能包含：新增/编辑药品信息，开启/关闭用药计划，到时用药提醒等。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img10.png'),
          title: '健康评估',
          data: '健康评估可以帮助用户在营养知识、态度、行为以及营养状况的改善等方面受益，解决其在生理、心理等方面的营养问题，从而提高其营养保健知识水平和能力；其意义在于：1、减少疾病的发生和防止病情的恶化；2、促进亚健康人群恢复健康；3、提高科学的营养水平，增强对疾病的抵抗力。'
        },
        {
          lable: 1,
          imgUrl: require('@/assets/imgs/health_img11.png'),
          title: '菏康饮食',
          data: '均衡饮食和适量运动对于人们十分重要，引导老年人正确认识健康饮食，培养健康饮食习惯；普及食材营养功效，智能推荐菜品及烹饪步骤。',
          btn: true
        }
      ]
    }
  },
  mounted() {
    this.scrollTop()
    document.title = GetPageTitle(this.title)
  },
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    },
    openProductDeatail() {
      this.$router.push({ path: '/Foods' })
    },
    openProperty() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/'
    },
    openPropertyRegister() {
      const w = window.open('about:blank')
      w.location.href = 'https://property.zghkyy.com/#/register'
    },
    openUser() {
      this.downApp = true
    },
    closeDownApp() {
      this.downApp = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.banner_box {
  width: 100%;
  height: 800px;
  background-image: url('../../assets/imgs/health_img0.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.b_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt_title {
  font-size: 50px;
  width: 640px;
  padding-bottom: 22px;
}
.bt_p {
  width: 640px;
}
.banner_btnBox {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.ci_txtBox {
  max-width: 630px;
}
.ci_title {
  font-size: 50px;
  padding-bottom: 22px;
}
.content_titBox {
  text-align: center;
  margin: 0 auto;
  margin-top: 150px;
}
.ct_innerBox {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}
.content_dataBox {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 40px 0;
}
.sd_items {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
  padding: 0 40px;
}
.sd_imgBox {
  width: 680px;
  height: 447px;
  border-radius: 24px;
  overflow: hidden;
}
.cit_btn {
  border-radius: 40px;
  background: #09bebe;
  color: #fff;
  font-size: 14px;
  width: 110px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  margin-top: 40px;
}
.bb_move {
  padding: 8px 24px;
  background: #09bebe;
  border-radius: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}
.cit_btn:hover,
.bb_move:hover {
  background: #24dddd;
  cursor: pointer;
}
.privilegeBox {
  margin-top: 150px;
  background: #f2f4f4;
  padding: 80px 40px;
}
.pi_items {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
  border-radius: 24px;
}
.pii_img {
  max-width: 154px;
  max-height: 154px;
}
.pii_tit {
  font-size: 30px;
  padding-bottom: 16px;
}
.service_data {
  padding-bottom: 150px;
}
</style>
