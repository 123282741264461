import Vue from 'vue'
import {
  Button,
  Container,
  Main,
  Header,
  Footer,
  Image,
  Row,
  col,
  Link,
  Collapse,
  CollapseItem,
  Form,
  FormItem,
  Input,
  Message,
  Dialog,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Carousel,
  CarouselItem,
  Checkbox,
  Select,
  Option
} from 'element-ui'

Vue.use(Button)
Vue.use(Container)
Vue.use(Main)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Image)
Vue.use(Row)
Vue.use(col)
Vue.use(Link)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
Vue.prototype.$message = Message
