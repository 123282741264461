<template>
  <div class="home">
    <el-container class="pageBg">
      <div class="pageTit">菏康安防体验版</div>
      <div class="pageContent">
        <div class="pc_item" v-for="(item, index) in list" :key="index" @click="openItem(item, index)">
          <div class="pci_tit">{{ item.title }}</div>
          <div class="pci_dec">{{ item.dec }}</div>
        </div>
      </div>
      <div class="page_dec">
        <span style="opacity: 0.6">体验版仅展示部分预警功能，更多功能请</span>
        <span @click="openDown()" class="downApp">下载菏康APP</span>
      </div>
    </el-container>
    <!-- 远程关闭阀门 -->
    <div class="colseGasBox" v-if="colseGasBoxShow === true">
      <div class="cgb_content">
        <div class="cgbc_tit">确认要关闭所有燃气阀门吗？</div>
        <div class="cgb_btn">
          <div class="cgbb_item" @click="colseGas()">
            <div>取消</div>
          </div>
          <div class="cgbb_item" @click="openColseGas()">
            <div>确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 报警提示音 -->
    <audio controls="controls" hidden src="../../assets/music/alarm_music.wav" ref="alarmMusic"></audio>
    <!-- 报警音频 -->
    <audio controls="controls" hidden src="../../assets/music/baojingyiyin.mp3" ref="baojingyiyin"></audio>
    <!-- 消息提示音 -->
    <audio controls="controls" hidden src="../../assets/music/clew_tone.wav" ref="clewTone"></audio>
    <!-- 超时提示音 -->
    <audio controls="controls" hidden src="../../assets/music/overtime.wav" ref="overtime"></audio>
  </div>
</template>
<script>
import GetPageTitle from '../../utils/getPageTitle'
import session from '@/utils/session'
import { userCloseGas, userSmartDevice } from '@/api/index.js'
// 定义websoket服务
let ws
export default {
  data() {
    return {
      title: '居家安防报警体验入口',
      propertyInfo: null,
      colseGasBoxShow: false,
      list: [
        {
          title: '安防预警',
          dec: '查看报警详情'
        },
        {
          title: '远程关闭',
          dec: '可远程关闭燃气阀门'
        },
        {
          title: '呼叫管家',
          dec: '紧急呼叫社区管家'
        }
      ]
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    document.title = GetPageTitle(this.title)
    this.propertyInfo = session.getPropertyInfo()
    this.propertyInits()
    const _this = this
    this.timer = setInterval(() => {
      _this.date = new Date()
    }, 1000)
  },
  methods: {
    // 开启websocket
    propertyInits() {
      this.lockReconnect = false // 避免重连的机制
      this.heartBeatClock = null // websocket的setTimeout函数
      this.try = null // 尝试重连
      const _this = this
      if (typeof WebSocket === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
      } else {
        // 用于接收结果的webcosket 服务
        // const socketUrl2 = 'ws://kwangda.com:8090/propertyWebsocket/' + this.mobile
        const socketUrl2 = 'wss://kwangda.com:8092/sysWebsocket/' + this.propertyInfo.mobile
        // const socketUrl2 = 'ws://127.0.0.1:8054'
        if (ws != null) {
          ws.close()
          ws = null
        }
        // 开启webSocket服务
        ws = new WebSocket(socketUrl2)
        console.log('正在开启...')
        // 打开事件
        ws.onopen = function () {
          console.log('设备WebSocket已打开')
        }
        // 浏览器手消息，获得从服务端发过来的消息事件
        ws.onmessage = function (msg) {
          if (msg.data === '888888') {
            console.log('WebSocket正常')
          } else {
            const msgInfo = JSON.parse(msg.data) // 对收到的数据进行解析
            console.log('msgInfo', msgInfo)
            _this.deviceInits(msgInfo)
          }
        }
        ws.onclose = (e) => {
          console.log('设备WebSocket已关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            reconnectWebSocket()
          }, 5000)
        }
        ws.onerror = (e) => {
          console.log('设备WebSocket异常关闭')
          clearTimeout(_this.try)
          _this.try = setTimeout(() => {
            _this.reconnectWebSocket()
          }, 5000)
        }
        // eslint-disable-next-line no-inner-declarations
        function reconnectWebSocket() {
          console.log('设备重连中...')
          if (_this.lockReconnect) {
            return
          }
          _this.lockReconnect = true
          // 没连接上会一直重连，设置延迟避免请求过多
          _this.heartBeatClock && clearTimeout(this.heartBeatClock)
          _this.heartBeatClock = setTimeout(() => {
            _this.propertyInits()
            _this.lockReconnect = false
          }, 5000)
        }
        // 心跳检测 与服务端约定666666为心跳检测
        //  每隔30s后向服务端发送消息 服务返回888888为正常
        var postBackMes30
        if (!postBackMes30) {
          window.postBackMes30 = setInterval(() => {
            // 这里放 websocket的传送信息方法
            var jsonData = { propertyId: this.propertyInfo.mobile, message: '666666' }
            // console.log('我是心跳检测', jsonData)
            ws.send(JSON.stringify(jsonData))
          }, 30 * 1000)
        }
      }
    },
    // 根据报警事件获取弹框信息
    async deviceInits(msgInfo) {
      if (msgInfo.deviceInfo.roomId === this.propertyInfo.roomId) {
        session.setData('eventInfo', msgInfo)
        // 播放报警提示音
        this.$refs.alarmMusic.currentTime = 0
        this.$refs.alarmMusic.play()
        this.$refs.baojingyiyin.currentTime = 0
        this.$refs.baojingyiyin.play()
        this.$router.push({ path: 'UserDomeWarn' })
      } else {
        console.log('没有我的数据')
      }
    },
    openDown() {
      this.$router.push({ path: 'UserAppDown' })
    },
    openItem(item, index) {
      const num = index
      if (num === 0) {
        this.$router.push({ path: 'UserDomeWarn' })
      } else if (num === 1) {
        this.colseGasBoxShow = true
      } else if (num === 2) {
        window.location.href = `tel:${this.propertyInfo.propertyPhone}`
      }
    },
    colseGas() {
      this.colseGasBoxShow = false
    },
    async openColseGas() {
      // 根据roomId查询设备列表
      const deviceList = await userSmartDevice(this.propertyInfo.roomId)
      if (deviceList.success) {
        const list = deviceList.data
        for (let i = 0; i < list.total; i++) {
          const deviceName = list.rows[i].deviceName
          if (deviceName == '智能燃气探测器') {
            const imei = list.rows[i].imei
            const res = await userCloseGas(imei)
            if (res.success) {
              this.$message.success('指令发送成功！')
              this.$refs.clewTone.currentTime = 0
              this.$refs.clewTone.play()
              this.colseGasBoxShow = false
            } else {
              this.$message.error('系统错误，请稍后重试')
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
p {
  padding: 0.25rem;
  margin: 0;
  line-height: 1.6;
}
.pageBg {
  background: #078b8b;
  height: 100vh;
  display: block;
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
}
.pageTit {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-align: center;
  color: #fff;
  padding: 2rem 0;
}
.pc_item {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem 0;
  text-align: center;
  margin-bottom: 1rem;
}
.pci_tit {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}
.pci_dec {
  font-size: 0.8rem;
  opacity: 0.5;
}
.page_dec {
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  margin-top: 1.5rem;
}
.downApp {
  text-decoration: underline;
  padding-left: 0.5rem;
}
.colseGasBox {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cgb_content {
  background: #fff;
  border-radius: 1rem;
  width: 75%;
}
.cgbc_tit {
  font-size: 1.1rem;
  text-align: center;
  margin: 1.5rem 0;
  color: #dd2424;
}
.cgb_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.5px solid #f2f3f4;
}
.cgbb_item {
  text-align: center;
  width: 50%;
  padding: 1rem 0;
  font-weight: bold;
}
</style>
