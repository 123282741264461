<template>
  <div class="home">
    <!-- 导航 -->
    <topNavVue />
    <el-container>
      <el-main class="mainBox">
        <!-- banner -->
        <div class="bannerBox">
          <div class="b_t_text">诚信服务、规范经营</div>
        </div>
        <!-- 详细信息 -->
        <div class="contentBox">
          <div class="content_con">
            <div class="content_tit">隐私政策</div>
            <!-- iframe元素用于展示HTML内容 -->
            <div v-html="content" class="contentStyle"></div>
          </div>
        </div>
      </el-main>
      <!-- 页脚 -->
      <footerNavVue />
    </el-container>
  </div>
</template>
<script>
import topNavVue from '../component/topNav.vue'
import footerNavVue from '../component/footerNav.vue'
import { getNoticeInfo } from '@/api/index.js'
export default {
  components: {
    topNavVue,
    footerNavVue
  },
  data() {
    return {
      content: null
    }
  },
  mounted() {
    this.getNoticeInfo()
  },
  methods: {
    async getNoticeInfo() {
      let id = '1592111229921550336'
      let res = await getNoticeInfo(id)
      if (res.success) {
        this.content = res.data.noticeContent
      } else {
        alert(res.message)
      }
    }
  }
}
</script>
<style scoped>
img {
  width: 100%;
  height: '100%';
}
.headerBox {
  position: fixed;
  width: 100%;
}
.h_inner {
  max-width: 1440px;
  padding: 0 40px;
  height: 76px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoBox {
  width: 76px;
  height: 40px;
}
.navTextBox {
  display: flex;
  color: #fff;
}
.el-container {
  display: block;
}
.n_text {
  font-size: 16px;
  padding-left: 80px;
  color: #fff;
  text-decoration: none;
}
.b_n_text {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
.bannerBox {
  height: 735px;
  background: url('../assets/imgs/aboutbg.png') no-repeat;
  background-size: auto 735px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.b_t_text {
  padding: 0 40px;
  font-size: 50px;
  font-weight: 500;
  color: #fff;
}
.contentBox {
  width: 100%;
}
.content_con {
  max-width: 1440px;
  margin: 0 auto;
  text-align: start;
  padding: 40px 0;
}
.content_tit {
  font-size: 40px;
  font-weight: 500;
  color: #202626;
  margin-bottom: 40px;
}
img {
  width: 100%;
  height: 100%;
}
</style>
<style>
.contentStyle a {
  color: #333;
}
</style>