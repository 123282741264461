import request from '@/utils/request'

// 商务合作申请
export function queryBusCoo(data) {
  return request({
    url: '/sys/web/busCoo',
    method: 'POST',
    data: data
  })
}
// 用户体验入口
export function userExperience(data) {
  return request({
    url: '/sys/userFamily/userExperience',
    method: 'POST',
    data: data
  })
}

// 解决/报修预警
export function queryDeviceWarningPut(id, data) {
  console.log(data)
  return request({
    url: '/sys/deviceWarning/' + id,
    method: 'PUT',
    data: data
  })
}
// 远程关闭燃气阀门
export function userCloseGas(data) {
  return request({
    url: '/sys/smartDevice/closeGas/' + data,
    method: 'GET',
  })
}
// 根据roomId查询设备列表
export function userSmartDevice(roomId) {
  return request({
    url: '/sys/smartDevice/list/' + roomId,
    method: 'POST',
    data: {
      page: 1,
      pageSize: 20
    }
  })
}
// 查询用户数量
export function userTotal() {
  return request({
    url: '/sys/userFamily/userTotal',
    method: 'GET',
  })
}
// 用户领取福利 相当于账号申请
export function userRegistration(data) {
  return request({
    url: '/sys/userFamily/userRegistration',
    method: 'POST',
    data: data
  })
}
// 仅发送注册验证码 不校验
export function userValidateCode(data) {
  return request({
    url: '/sys/userFamily/validateCode/' + data,
    method: 'GET',
  })
}
// 用户申请服务
export function userQuick(data) {
  return request({
    url: '/sys/userFamily/userQuick',
    method: 'POST',
    data: data
  })
}
// 查询隐私政策
export function getNoticeInfo(id) {
  return request({
    url: '/sys/notice/' + id,
    method: 'GET',
  })
}